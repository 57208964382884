<template>
  <b-modal id="ModalMasiveUpload" :title="$t('Carga masiva')" modal-class="dialog-600" no-close-on-esc no-close-on-backdrop @close="close">
    <form-render
      @send="onImport"
      :key="formRenderMasive"
      :form.sync="form"
      :fields="fields"
      containerButtonsClass="col-sm-12"
      ref="formRefMasiveUpload"
    >
      <template #title-info>
        <p>{{$t('Esta acción puede tomar varios minutos.')}}</p>
      </template>
      <template #import-info>
        <label class="label-form">
          Archivo de importación <span class="text-danger">*</span>
          (<b-button @click="downloadExcel" variant="link" class="p-0 font-weight-normal"><small>Descargar formato</small></b-button>)
        </label>
      </template>
    </form-render>    
    <template #modal-footer="">
      <b-button @click="ok" variant="success" :disabled="loading.import">
        <b-spinner v-if="loading.import" small></b-spinner>
        <span> {{$t('Importar')}}</span>
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import BaseServices from '@/store/services/index'

export default {
  name:'modal-masive-upload',
  props: ['sellerInfo'],
  data() {
    return {
      formRenderMasive: 0,
      loading: {
        import: false
      },
      form: {},
      fields: [],
      masiveReturns: { id: 'import-return-deliveries', type: 'import-return-deliveries', text: 'Carga masiva de devoluciones', template: 'ejemplo_de_carga_de_devoluciones_masiva.xlsx' },
      shared_import: { id: 'import-deliveries', type: 'import-deliveries', text: 'Carga masiva Chile', country_id: 1, template: 'ejemplo_de_carga_masiva.xlsx' },
      importTypes: [
        { id: 'import-deliveries-massive-marketcenter', type: 'import-deliveries-massive-marketcenter', text: 'Envios masívos por marketcenter', template: 'formato_envios_masivos_marketcenter.xlsx' },
        { id: 'retry-orders-ecommerce', type: 'retry-orders-ecommerce', text: 'Reprocesar pedidos ecommerce', template: 'formato_reprocesar_pedido_ecommerce.xlsx' },
        { id: 'import-deliveries-co', type: 'import-deliveries', text: 'Carga masiva Colombia', country_id: 3, template: 'ejemplo_de_carga_masiva_colombia.xlsx' },
        { id: 'import-deliveries-pe', type: 'import-deliveries', text: 'Carga masiva Perú', country_id: 4, template: 'ejemplo_de_carga_masiva_peru.xlsx' },
        { id: 'import-deliveries-mx', type: 'import-deliveries', text: 'Carga masiva México', country_id: 2, template: 'ejemplo_de_carga_masiva_mexico.xlsx' },
        { id: 'import-return-deliveries', type: 'import-return-deliveries', text: 'Carga masiva de devoluciones', template: 'ejemplo_de_carga_de_devoluciones_masiva.xlsx' },        
        { id: 'import-deliveries-update', type: 'import-deliveries-update', text: 'Actualización de información de envios', template: 'ejemplo_de_actualizacion_masiva.xlsx' },
        { id: 'import-deliveries-update-status', type: 'import-deliveries-update-status', text: 'Actualización de estados (tracking)', template: 'ejemplo_de_actualizacion_de_estados_masiva.xlsx' },
        { id: 'import-deliveries-reinject', type: 'import-deliveries-reinject', text: 'Reinyectar másivo', template: 'ejemplo_de_reinyeccion_masiva.xlsx' },
        { id: 'import-deliveries-change-carrier-and-packages', type: 'import-deliveries-change-carrier-and-packages', text: 'Reinyección de courier y bultos', template: 'ejemplo_de_cambio_carrier_y_bulto.xlsx' },
        { id: 'import-warehouses', type: 'import-warehouses', text: 'Carga másiva de bodegas', template: 'ejemplo_de_incorporacion_de_bodegas_masiva.xlsx' },
        { id: 'import-deliveries-update-ot', type: 'import-deliveries-update-ot', text: 'Actualizador de OT masivo', template: 'ejemplo_de_actualizacion_de_seguimiento_masiva.xlsx' },
        { id: 'delete-deliveries-statuslogs', type: 'delete-deliveries-statuslogs', text: 'Eliminar estados masivos', template: 'Ejemplo_borrado_masivo_de_logs.xlsx' },
        { id: 'massive-delivery-tracking', type: 'massive-delivery-tracking', text: 'Tracking masivo', template: 'ejemplo_de_tracking_masivo.xlsx' },
        { id: 'massive-current-status-change', type: 'massive-current-status-change', text: 'Cambio masivo de estado actual', template: 'ejemplo_de_cambio_masivo_de_estado_actual.xlsx' },
        { id: 'shipping-splitter', type: 'shipping-splitter', text: 'Fraccionador de envios', template: 'ejemplo_de_fraccionador_de_envios.xlsx' },
        { id: 'import-return-update-status', type: 'import-return-update-status', text: 'Cambio masivo de estado de devoluciones', template: 'ejemplo_de_cambio_masivo_de_estado_devoluciones.xlsx' },
        { id: 'cod-billing-statuses', type: 'cod-billing-statuses', text: 'Codigo de estados de facturación', template: 'ejemplo_codigos_estado_facturacion.xlsx' },
        { id: 'massive-publication', type: 'massive-publication', text: 'Publicador masivo de envios', template: 'ejemplo_de_publicador_masivo.xlsx' },
        { id: 'import-returns-update-ot', type: 'import-returns-update-ot', text: 'Actualizador de OT masivo (devoluciones)', template: 'ejemplo_de_cambio_masivo_ot_devoluciones.xlsx' }
      ],
      cmpMkpOptions: [],
      importTypesCompanyMarketplace: [],
      companyDataFake: {},
      baseService: new BaseServices(this)
    }
  },
  computed: {
    ...mapGetters({
      mySession: 'getSession',
      companyInfo: 'getCompanyInformation'
    })
  },
  watch: {
    mySession() {
      if (this.mySession?.id) this.setInitialData()
    },
    sellerInfo() {
      if (['seller', 'ecommerce'].includes(this.mySession?.role)) {
        const indexUp = this.m_getElementIndex(this.fields, 'name', 'uploadType')
        this.cmpMkpOptions.push(...this.setCmpMkpImports(this.sellerInfo.data.company))
        this.fields[indexUp].options = this.cmpMkpOptions
        this.form.uploadType = this.cmpMkpOptions[0]
      }
    },
    companyInfo() {
      if (['marketplace'].includes(this.mySession?.role)) {
        const indexUp = this.m_getElementIndex(this.fields, 'name', 'uploadType')
        this.cmpMkpOptions.push(...this.setCmpMkpImports(this.companyInfo.data.company))
        this.fields[indexUp].options = this.cmpMkpOptions
        this.form.uploadType = this.cmpMkpOptions[0]

      }
    } 
  },
  mounted() {
    this.setInitialData()
  },

  methods: {
    setInitialData() {
      this.fields = [
        { name: 'title-info', useSlot: true, containerClass: 'col-sm-12 container-info'},
        { 
          fieldType: 'FieldSelect',
          name: 'uploadType', label: this.$t('Tipo de subida'), containerClass: 'col-12 container-info px-2', validation: 'required',
          aditionalLabel: {type: 'secondary', text:this.$t('(cada uno tiene formato de excel diferente)')}
        },
        {
          fieldType: 'FieldInput',
          label: this.$t('Correo'), name: 'email', containerClass: 'col-12 container-info px-2',
          aditionalLabel: {type: 'secondary', text:this.$t('(Escribe el correo llegará una notificación cuando el proceso de importación finalice)')}, validation: 'required'
        },
        { name: 'import-info', useSlot: true, containerClass: 'col-sm-12 container-info px-2 mb-0'},
        {
          fieldType: 'FieldFile', name: 'file', label: '', containerClass: 'col-sm-12 container-info px-2', validation: 'required', accept: '.xls, .xlsx'
        }
      ]
      this.form.email = this.mySession?.email
      const indexUp = this.m_getElementIndex(this.fields, 'name', 'uploadType')
      if (['admin', 'superadmin'].includes(this.mySession?.role)) {
        this.importTypes.unshift(this.shared_import)
        this.fields[indexUp].options = this.importTypes
        //Settear un valor por defecto
        this.form.uploadType = this.fields[indexUp]?.options[0]
      }
      if (['seller', 'ecommerce', 'marketplace'].includes(this.mySession?.role)) {
        //Validamos mostrar masive returns
        const canAccessReturn = this.$session.get('cas_user')?.user_access?.can_access_return
        if (canAccessReturn) this.cmpMkpOptions.push(this.masiveReturns)
        if (['seller', 'ecommerce'].includes(this.mySession?.role)) return

        if (!Object.keys(this.companyInfo).length) {
          this.$store.dispatch('fetchService', { name: 'getCompanyInformation', params: { company_id: this.mySession.organization.id } })
        } else {
          this.cmpMkpOptions.push(...this.setCmpMkpImports(this.companyInfo.data.company))
          this.fields[indexUp].options = this.cmpMkpOptions
        }
      }
    },
    setCmpMkpImports(company) {
     
      const getTemplate = (country_id) => {
        switch (country_id) {
        case 3: return 'ejemplo_de_carga_masiva_colombia.xlsx'
        case 4: return 'ejemplo_de_carga_masiva_peru.xlsx'
        case 2: return 'ejemplo_de_carga_masiva_mexico.xlsx'
        default: return 'ejemplo_de_carga_masiva.xlsx'
        }
      }
      const cmpMkp = []
      const country_id = company?.country_id || this.mySession?.country?.id || 1
      cmpMkp.push({
        id: `import-deliveries-${company?.country_code?.toLowerCase()}`,
        type: 'import-deliveries',
        text: 'Carga masiva',
        country_id,
        template: getTemplate(country_id)
      })
      const customizationAccessCheck = (key, template, name) => {
        if (this.hasCustomizationAccess(company, key)) {
          cmpMkp.push({
            id: name,
            type: name,
            text: template.name,
            template: template.template
          })
        }
      }
      customizationAccessCheck('bulk_change_carrier', {
        text: 'Reinyección de courier y bultos',
        template: 'ejemplo_de_cambio_carrier_y_bulto.xlsx'
      }, 'import-deliveries-change-carrier-and-packages')

      cmpMkp.push({
        id: 'import-deliveries-massive-marketcenter',
        type: 'import-deliveries-massive-marketcenter',
        text: 'Envíos masivos por marketcenter',
        template: 'formato_envios_masivos_marketcenter.xlsx'
      })

      customizationAccessCheck('can_split_deliveries', {
        text: 'Fraccionador de envíos',
        template: 'ejemplo_de_fraccionador_de_envios.xlsx'
      }, 'shipping-splitter')

      cmpMkp.push({
        id: 'import-deliveries-update-status',
        type: 'import-deliveries-update-status',
        text: 'Actualización de estados (tracking)',
        template: 'ejemplo_de_actualizacion_de_estados_masiva.xlsx'
      })

      this.shared_import = cmpMkp[0]

      return cmpMkp
    },
    hasCustomizationAccess (company, customizationKey) {
      const companyCustomization = company?.customization?.[customizationKey]
      const marketplaceCustomization = company?.company_marketplaces?.[0]?.marketplace?.customization?.[customizationKey]

      return !!companyCustomization || !!marketplaceCustomization
    },
    downloadExcel() {
      window.open(`https://storage.cloud.google.com/templates-excel/${this.form.uploadType.template}`, '_blank')
    },
    ok (e) {
      this.$refs.formRefMasiveUpload.checkForm()
    },
    onImport (data) {
      this.loading.import = true
      const queryParams = {
        file: data.file,
        type: data.uploadType.type,
        email: data.email || null
      }
      this.baseService.callUploadFileCustomParams('uploadDeliveriesMasive', queryParams)
        .then(response => {
          const resp = response.data
          let message = ''
          if (resp?.resume?.repeated.length > 0) {
            message = `, se encotraron ${resp.resume.repeated.length} repetidos y es/son los siguiente: ${resp.resume.repeated}`
          }
          this.$bvToast.toast(`Recibirás un correo cuando la importación finalice, este proceso puede durar varios minutos ${message}`, {
            title: '¡Importación realizada con éxito.!',
            variant: 'success',
            solid: true
          })
        })
        .catch((err) => {       
          console.error('err', err)
          this.$alert(this.$t('msg-problema-cargar-datos', {code: err.message ? err.message : err}))
        })
        .finally(() => {
          this.loading.import = false
        }) 
    },
    close () {
      this.form.file = null
    }
  }
}
</script>