import { environment } from '@/environments/environment'
import useJwt from '@/auth/jwt/useJwt'

const services = [
  { name: 'getOrganizations', url: `${environment.dubaiApiUrl}/organizations`, method: 'get'},
  { name: 'getCountry', url: `${environment.dubaiApiUrl}/countries/{id}`, method: 'get'},
  { name: 'getShippersByOrganization', url: `${environment.dubaiApiUrl}/organizations/{organization_id}/shippers`, method: 'get'},
  { name: 'getDeliveriesEstambul', url: `${environment.estambulApiUrl}/deliveries`, method: 'get'},
  // { name: 'getDeliveryLabels', url: `${environment.estambulApiUrl}/api/shippers/{shipper_id}/delivery-labels`, method: 'post'},
  { name: 'getDeliveryLabels', url: `${environment.estambulApiUrl}/deliveries/print`, method: 'post'},
  { name: 'postDeliveriesPrint', url: `${environment.apiPlatform}/v1/print-deliveries`, method: 'post', notCancel: true},
  { name: 'assignToSummary', url: `${environment.estambulApiUrl}/deliveries/assign-to-summary`, method: 'put'},
  { name: 'exportDeliveries', url: `${environment.estambulApiUrl}/deliveries/export`, method: 'post'},
  { name: 'getCountries', url: `${environment.dubaiApiUrl}/countries`, method: 'get', notCancel: true},
  { name: 'getCarriersNY', url: `${environment.newyorkApiUrl}/v2/coverage/carriers`, method: 'get' },
  { name: 'getCarrierServicesNY', url: `${environment.newyorkApiUrl}/v3/coverage/countries/{country_id}/carriers/{carrier_id}/services`, method: 'get' },
  { name: 'getShippers', url: `${environment.dubaiApiUrl}/shippers`, method: 'get'},
  { name: 'getShippersByOrganizationSimplifiedQueryParams', url: `${environment.dubaiApiUrl}/organizations/{organization_id}/shippers?simplified=true`, method: 'get' },

  { name: 'getLevels', url: `${environment.dubaiApiUrl}/countries/{country_code}/levels`, method: 'get'},
  { name: 'getLevelsByLevelId', url: `${environment.dubaiApiUrl}/levels/{level_id}`, method: 'get'},
  { name: 'getDeliveryStatuses', url: `${environment.apiUrl}/delivery-statuses`, method: 'get'},
  { name: 'getCouriers', url: `${environment.tokyoApiUrl}/v1/carriers/carrier-organization-shippers-new`, method: 'get', notCancel: true }
]

export default class DeliveryService {
  constructor(context) {
    this.context = context
  }
  async callService (name, queryParams = {}, params = {}) {
    const service = services.filter(service => service.name === name)[0]
    return useJwt.genericService(service, queryParams, params)
  }
  
  async callMultipleServices (callArray, byName = false) {
    const servicesArray = callArray.map(call => ({ service: services.filter(service => service.name === call.name)[0], ...call }))
    return useJwt.genericMultipleService(servicesArray, byName)
  }

  getDeliveriesByStatusId (arrServices) {
    return this.callMultipleServices(arrServices, true)
      .then(response => {
        const obj = {}
        arrServices.map(el => {
          if (el.id === 'getDeliveries' && response.getDeliveries) {
            obj[el.id || el.name] = this.parseRowsDeliveries(response[el.id || el.name].data)
            obj.total = response[el.id || el.name].links.meta.data_count
          }
          if (el.id === 'getShippers' && response.getShippers) obj[el.id || el.name] = response.getShippers.data.map(c => ({...c, text: c.name1}))
          if (el.id === 'getOrganizations' && response.getOrganizations) obj[el.id || el.name] = response.getOrganizations.data.map(c => ({...c, text: c.name}))
        })
        return obj
      })
      .catch(err => {
        console.error(err)
        this.context.$alert(this.context.$t('msg-problema-cargar-datos', {code: err}))
        return []
      })
  }
  
  parseRowsDeliveries (list) {
    return list.map(el => {
      return {
        ...el,
        id: el.order.id,
        shipper_name: el.shipper?.name1 || '',
        carrier_name: el.carrier?.code || '',
        status_name: `<span class="badge badge-light-warning">${el.order?.status?.status || el.order?.status?.name || ''}</span>`, //el.order.status.status,
        imported_id: el.order?.imported_id || '',
        tracking_number: el.order?.identifiers?.tracking_number || '',
        level4: el.destination?.address?.level4 || '',
        level3: el.destination?.address?.level3 || '',
        level2: el.destination?.address?.level2 || '',
        level1: el.destination?.address?.level1 || '',
        created_at: el.order?.status?.date ? this.context.$options.filters.dbDateToFormat(el.order.status.date, 'D T') : null,
        deadline_date: el.extra_fields ? el.extra_fields['fecha retiro'] : '',
        booleanNewTicket: el.order?.service_type === 'Pasarela' || el.shipper?.ticket_force_creation
      }
    })
  }
}
