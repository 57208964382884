<template>
  <b-modal id="ModalMaps" :title="$t('Debido a las condiciones del courier, debe confirmar la dirección.')" :ok-title="$t(editAddress ? 'Confirmar dirección nueva' : 'Editar')" ok-variant="warning" modal-class="dialog-600" 
  ok-only no-close-on-esc no-close-on-backdrop @close="close" hide-footer>
    <div v-if="loading.loading" class="my-5">
      <div class="text-center">
        <b-spinner variant="primary" style="width: 3rem; height: 3rem;" label="Text Centered"></b-spinner>
      </div>
    </div>
    <div v-else>
      <p v-show="!alertAddress">Arrastre el indicador del mapa para que se ajuste correctamente a la dirección deseada <b-spinner v-show="loading.maps" variant="primary" small label="Text Centered"></b-spinner></p>
      <h3 class="text-danger" v-show="alertAddress">La dirección ingresada no pudo ser encontrada, por favor arrastre el indicador o modifique la dirección</h3>
      <form-render
        :key="formRenderModalMaps"
        :form.sync="form"
        :fields="fields"
        ref="formRefModalMaps"
      ></form-render>
      <div class="my-1" v-if="!!addressClean">
        <google-map id="map" :infoMap="showMap" :full_address.sync="addressClean" :update="changeDirection" :size="{width: '100%', height: '300px'}" />
      </div>
      <form-render
        :buttonSend="buttonSend"
        @send="createDelivery"
        :key="formDisable"
        :form.sync="form"
        :fields="fields2"
        containerButtonsClass="col-sm-12 d-flex justify-content-end my-2"
        ref="formDisable"
      />
      
    </div>
  </b-modal>
</template>

<script>
import BaseServices from '@/store/services/index'

export default {
  name:'modal-maps',
  props: ['dataDelivery', 'putDelivery'],
  data() {
    return {
      baseService: new BaseServices(this),
      formRenderModalMaps: 0,
      formDisable: 2,      
      showMap: {draggable: true},
      loading: {
        loading: true,
        maps: true
      },
      buttonSend: { color: 'warning', text: 'Confirmar y continuar' },
      form: {},
      fields: [],
      fields2: [],
      delivery: {},
      editAddress: false,
      incompleteAddress: [],
      validAddress: [],
      valid_full_address: {},
      alertAddress: false,
      addressInfoGoogleMaps: '',
      latAddress: null,
      lngAddress: null,
      addressNotFormatted: null,
      country_id: null,
      addressClean: '',
      country: {
        1: 'Chile',
        3: 'Colombia',
        2: 'Mexico',
        4: 'Peru',
        6: 'Ecuador',
        5: 'Argentina'
      }
    }
  },
  computed: {
  },
  watch: {
    dataDelivery () {
      // El delivery lo paso a una variable global para llamarlo al agregar un nuevo envio luego de la confirmación
      this.delivery = this.dataDelivery.payload
      this.country_id = this.dataDelivery.country_id
      this.validateAddress(this.delivery.full_address)
    }
  },
  mounted() {
    this.setInitialData()
  },
  methods: {
    setInitialData() {
      // Llamamos a la función que valida la dirección ingresada
      this.fields = [
        { 
          fieldType: 'FieldAutocomplete', label: this.$t('Dirección'), name: 'address', containerClass: 'col-12 dress-container', validation: 'required',
          searchOnType: { fx: this.searchGoogleAddress, nChar: 3, debounce: 300, allowSearchAsValue: true, persistSearch: true },
          iconLeft: {icon: 'MapPinIcon', size: '20', stroke: 1.5, color: '#f18805', customizeTopInMain: '4.3rem'}, change: this.changePosition
        }        
      ]
      this.fields2 = [
        { fieldType: 'FieldInput', label: this.$t('Latitud'), name: 'lat', containerClass: 'col-6', disabled: true },
        { fieldType: 'FieldInput', label: this.$t('Longitud'), name: 'lng', containerClass: 'col-6', disabled: true }
      ]
    },
    validateAddress(address) {
      // Dirección sin formato para mostrar en el input
      this.addressNotFormatted = address
      this.delivery.longitude = null
      this.delivery.latitude = null
      const place_name = this.delivery.place ? this.delivery.place : ''
      if (address && address.length > 4 && !!place_name) {
        if (this.delivery) {
          this.delivery.latitude = null
          this.delivery.longitude = null
          this.delivery.address_id = null
        }
        const regex = /#/ig
        let addressClean = `${address.replaceAll(regex, '')}, ${place_name}`
        if ([3, 4, 5, 6].includes(this.country_id)) {
          addressClean += `, ${this.delivery.place_level_1}, ${this.country[this.country_id]}`
        }
        if ([2].includes(this.country_id)) {
          addressClean += `, ${this.delivery.zipcode}, ${this.country[this.country_id]}`
        }
        this.addressClean = addressClean
        this.loading.loading = false
      }
    },
    changeDirection (new_direction) {
      this.form.address = new_direction
      this.form.lat = new_direction.location.lat
      this.form.lng = new_direction.location.lng
      this.formRenderModalMaps++
      this.formDisable++
      this.loading.maps = false
    },
    searchGoogleAddress (value) {
      return this.baseService.callService('getGoogleAddress', {address: value, country_id: this.country_id})
        .then(resp => {
          const validateAddress = resp.data.filter((el, index) => {
            const indexStreetName = el.address_components.findIndex(el => el.types.includes('route'))
            const indexStreetNumber = el.address_components.findIndex(el => el.types.includes('street_number'))
            return indexStreetName !== -1 && indexStreetNumber !== -1
          })
          const listAddress = validateAddress.length > 0 ? validateAddress : resp.data
          const resultAddress = listAddress.map(address => {
            return {
              id: address.place_id,
              formatted_address: address.formatted_address,
              text: address.formatted_address,
              location: address.geometry.location
            }
          })
          resultAddress.suggestions_address_validate_google = validateAddress.length === 0
          return resultAddress
        
        })
    },
    getFullAddress (value) {
      let text = `${value}, `
      if (this.country_id) {
        this.internalLevels[this.formPudo.country.code.toLowerCase()].required.map(level => {
          if (this.formPudo[`level${level}_pudo`] && this.formPudo[`level${level}_pudo`].id) {
            text += `${this.formPudo[`level${level}_pudo`].text},`
          }
          text = text.substring(0, text.length - 1)
        })
      }
      return text
    },
    changePosition (name, value) {
      if (value?.id) {
        this.showMap = {
          latitude: value.location.lat,
          longitude: value.location.lng,
          title: value.formatted_address,
          draggable: true
        }
      }
    },
    createDelivery (form) {
      this.buttonSend.disabled = true
      this.delivery.full_address = this.form.address.text
      this.delivery.latitude = this.form.lat
      this.delivery.longitude = this.form.lng
      this.$emit('putDelivery', this.delivery)
    },
    close () {}
  }
}
</script>