<template>
  <div class="company-dialog">
    <b-modal
      id="modalAssignSummary" 
      centered
      modal-class="modal-warning dialog-600 custom-dialog"
      hide-footer
      title="Asignar a manifiesto"
    >
      <div class="mt-0 text-center">
        <p class="mb-2"><strong>Los envíos seleccionados seran asigandos a un manifiesto abierto disponible.</strong></p>
        <span>{{deliveriesIds.length}} envío(s) seleccionados</span> 
        <p class="mb-2"><strong>Si no se encuentra un manifiesto, se creara uno nuevo.</strong></p>
        <div class="container my-2">
          <div class="row d-flex justify-content-around">
            <b-button class="col-md-5" variant="success" @click="assignToSummary()"> <b-spinner v-if="loading.assignSummary" type="grow"></b-spinner> <span v-else>{{$t('Asignar a manifiesto')}}</span> </b-button>
            <b-button class="col-md-5" variant="warning" @click=" () => this.$bvModal.hide('modalAssignSummary')"> <span>{{$t('Volver')}}</span> </b-button>
          </div>
        </div>

      </div>
    </b-modal>
  </div>
</template>

<script>
import DeliveryService from '../delivery.service'
export default {
  props: ['deliveriesIds', 'processType', 'refreshTable'],
  data() {
    return {
      deliveryService: new DeliveryService(this),
      loading: {
        assignSummary: false
      }
    }
  },
  methods: {
    assignToSummary () {
      this.loading.assignSummary = true
      const data = {
        delivery_ids: this.deliveriesIds
      }
      this.deliveryService.callService('assignToSummary', data, null)
        .then(resp => {
          this.$success(resp.message)
          if (!!this.refreshTable) this.$emit('setTable')
        })
        .catch(error => {
          this.$alert(this.$t('msg-problem-assign', {code: error}))
        })
        .finally(_ => {
          this.loading.assignSummary = false
          this.$bvModal.hide('modalAssignSummary')
        })
    }
  }
}
</script>

<style scoped>

</style>