<template>
  <div>
    <div class="border-bottom pb-2">
      <h4 class="mb-1">Datos del {{ title1 }}</h4>
      <!-- skeleton -->
      <div class="row" v-if="!mySession.id">
        <div class="col-12 col-md-3 mb-1" v-for="_ in [1,2]" :key="`fom1${_}`">
          <b-skeleton width="30%"/>
          <b-skeleton type="input"/>
        </div>
      </div>
      <!-- Form 1 -->
      <form-render :fields="fields" :key="keyFormRender1" :form.sync="form" ref="formRenderLogistic1">
        <template #newWarehouse>
          <b-button
            :disabled="(isAdmin && (is.DCreate && !companyId)) "
            @click="$bvModal.show('ModalWarehouseCompanies')"
            variant="outline-light"
            v-b-tooltip :title="$t('Crear nueva bodega o lugar de retiro')" size="md"
          >
            <feather-icon icon="PlusIcon"/>
          </b-button>

          <b-button
            v-if="is.DCreate"
            @click="$bvModal.show('ModalMasiveUpload')"
            variant="warning"
            class="push-right"
          >
            Carga masiva <feather-icon icon="UploadIcon"/>
          </b-button>
        </template>
      </form-render>
    </div>
    <b-row>
      <b-col cols="12" md="6">
        <h4 class="my-1">Datos del {{ title2 }}</h4>
        <!-- skeleton -->
        <div class="row" v-if="!mySession.id">
          <div class="col-12 col-md-6 mb-1" v-for="_ in [1,2,3,4]" :key="`fom2${_}`">
            <b-skeleton width="30%"/>
            <b-skeleton type="input"/>
          </div>
          <div class="col-12">
            <b-skeleton width="30%"/>
            <b-skeleton type="input" height="80px"/>
          </div>
        </div>
        <!-- Form -->
        <form-render :fields="fields2" :key="keyFormRender2" :form.sync="form" ref="formRenderLogistic2" containerButtonsClass="vx-col w-full md:w-1/4 container-button">
          <template #infoPudo>
            <label v-if="infoPudo">Dirección:</label>
            <div>
              <b>{{infoPudo}}</b>
            </div>
          </template>
          <template #infoAgency>
            <label v-if="infoAgency">Dirección:</label>
            <div>
              {{infoAgency}}
            </div>
          </template>
        </form-render>
      </b-col>
      <b-col cols="12" md="6" class="border-left">
        <h4 class="my-1">{{$t('Datos de distribución')}}</h4>
        <!-- skeleton  v-if="!mySession.id"-->
        <div class="row"  v-if="!mySession.id">
          <div class="col-6">
            <b-skeleton width="30%"/>
            <b-skeleton type="input"/>
          </div>
          <div class="col-12 mt-1"></div>
          <div class="col-12 col-md-6 mb-1" v-for="_ in [1,2]" :key="`fom3a${_}`">
            <b-skeleton width="30%"/>
            <b-skeleton type="input"/>
          </div>
          <div class="col-12 col-md-3 mb-1" v-for="_ in [1,2,3,4]" :key="`fom3b${_}`">
            <b-skeleton width="80%"/>
            <b-skeleton type="input"/>
          </div>
          <div class="col-12 col-md-4 mb-1" v-for="_ in [1,2,3]" :key="`fom3c${_}`">
            <b-skeleton width="30%"/>
            <b-skeleton type="input"/>
          </div>
          <div class="col-12">
            <b-skeleton width="30%"/>
            <b-skeleton type="input" height="80px"/>
          </div>
        </div>
        <!-- Form -->
        <form-render :fields="fields3" :key="keyFormRender3" :form.sync="form" ref="formRenderLogistic3" containerButtonsClass="vx-col w-full md:w-1/4 container-button">
          <template #infoCompensation>
            <span class="text-info font-weight-normal">
              <feather-icon icon="InfoIcon"/> Monto máximo a indemnizar sin seguro adicional {{form.carrier.max_compensation_amount}} (seguro obligatorio desde $1000 declarados).
            </span>
          </template>
        </form-render>
        <p v-show="!!estimatedPriceDeliveryValue && showPrice" class="px-1">
          Precio estimado del envío: <strong>{{estimatedPriceDeliveryValue}}</strong>
          <strong v-if="!!estimatedPriceDeliveryValue && form.company && form.company.company_id === 4"> + IGV</strong>
        </p>
        <b-button ref="createDeliveryButton" variant="warning" class="push-right" :disabled="disabled.sendForm" @click="sendForm">
          <span v-if="this.type === 'DCreate'"><b-spinner v-show="disabled.sendForm" small/> Guardar y continuar</span>
          <span v-else><i class="fa fa-spinner fa-spin mr-1" aria-hidden="true" v-if="show.loadingSendForm" /><font-awesome-icon v-if="!theReturn" :icon="['fa', 'plus']" /> {{ theReturn ? 'Editar' : 'Crear'}} devolución</span>
        </b-button>
      </b-col>
    </b-row>
  <modal-warehouse-companies :companyId="companyId"/>
  <modal-masive-upload :sellerInfo="companyInfo"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BaseServices from '@/store/services/index'
import ModalWarehouseCompanies from '@/views/modules/companies/companies-id/warehouses/ModalWarehouseCompanies.vue'
import ModalMasiveUpload from './ModalMasiveUpload.vue'
import Swal from 'sweetalert2'

export default {
  name: 'logistics-form',
  components: { ModalWarehouseCompanies, ModalMasiveUpload },
  props: ['theReturn', 'services', 'type', 'showLoadingAlert', 'internalLevels', 'onSave'],
  data () {
    return {
      is: {
        DCreate: false,
        DInfo: false,
        RCreate: false,
        RInfo: false
      },
      baseService: new BaseServices(this),
      title1: 'origen',
      title2: 'destinatario',
      isDisabledEdit: true,
      isAdmin: true,
      show: {
        loadingSendForm: false,
        modalWarehouse: false
      },
      estimatedPriceDeliveryValue: null,
      companyId: '',
      ObjPlace: null,
      showPrice: false,
      infoPudo: '',
      infoAgency: '',
      form: {},
      fields: [],
      fields2: [],
      fields3: [],
      formFilter: {},
      optionsDistributionType: [
        { id: 'home', text: 'Domicilio', value: true },
        { id: 'pudo', text: 'Punto de retiro', value: false },
        { id: 'agency', text: 'Agencia de courier', value: false }
      ],
      warehouse: [],
      keyFormRender1: 0,
      keyFormRender2: 0,
      keyFormRender3: 0,
      disabled: {
        sendForm: false
      },
      editing: false,
      levelCountry: '',
      maxPricesByCountry: {
        1:{ 
          profile: {
            cenco: { warning: 500000, alert: 999999 },
            normal: { warning: 500000, alert: null }
          } 
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      mySession: 'getSession',
      warehouseCompanies: 'getWarehouseCompanies',
      companyInfo: 'getCompanyInformation',
      courierCompanies: 'getCouriers',
      courierCompanies2: 'getCourierConfiguration',
      levels: 'getLevels',
      countries: 'getCountries',
      shippersByOrganization: 'getShippersByOrganizationSimplified'
    })
  },
  watch: {
    mySession () {
      if (this.mySession?.id) {
        this.setInitialData()
      }
    },
    courierCompanies() {
      const indexC = this.fields3.findIndex(el => el.name === 'carrier')
      this.fields3[indexC].disabled = false
      this.fields3[indexC].options = this.courierCompanies
      this.keyFormRender3++
    },
   
    warehouseCompanies() {
      const indexW = this.m_getElementIndex(this.fields, 'name', 'destination')
      this.fields[indexW] = {
        ...this.fields[indexW],
        options: this.warehouseCompanies.rows.map(value => ({...value, text: value.name})),
        disabled: false
      }
      this.keyFormRender1++

    },
    levels () {},
    companyInfo() {
      if (!Object.keys(this.companyInfo).length) return
      if (['seller', 'ecommerce'].includes(this.mySession?.role) && this.is.DCreate) {
        const companyIndex = this.m_getElementIndex(this.fields, 'name', 'company')
        const company = this.companyInfo.data.company
        this.fields[companyIndex].options = [{...company, country_code: company.country.code, text: company.name1 }]
        this.form.company = this.fields[companyIndex].options[0]
        this.getCompanyData('_', this.form.company)
      }
    },
    services: {
      handler () {
        this.setSelectOptions('destination', this.fields, this.services.warehouses)
        this.setSelectOptions('carrier', this.fields3, this.services.carriers)
        if (this.services.warehouses) this.setWarehouseValue()
        if (this.services.carriers) {
          this.form.carrier = this.services.carriers.filter(el => this.theReturn.carrier.code === el.code)[0]
          this.changeCarrier(null, this.form.carrier)
        }
        if (this.services.pudos && this.services.agencies) {
          this.setDistributionType()
        }
        if (this.theReturn?.status) this.verifyStatus()
      },
      deep: true
    },
    theReturn () {
      this.setDeliveryToShow('watch')
      if (this.theReturn.status) this.verifyStatus()
    },
    'form.price' (price) {
      const countryId = this.form.company?.country_id
      //only for Chile in the meantime 
      if (countryId === 1) this.checkPrice(price, countryId)
    }
  },
  mounted () {
    if (this.theReturn?.id) this.setDeliveryToShow('mounted')
    if (this.mySession?.id) this.setInitialData()
  },
  methods: {
    setDeliveryToShow (from) {
      this.form = {
        type: this.theReturn?.type,
        id: this.theReturn?.id,
        shipper: this.theReturn?.shipper,
        company: this.theReturn.shipper ? this.theReturn.shipper.name : '',
        company_id: this.theReturn.shipper ? this.theReturn.shipper.id : 0,
        name: this.theReturn?.origin?.contact?.name,
        cellphone: this.theReturn?.origin?.contact?.phone,
        email: this.theReturn?.origin?.contact?.email,
        full_address: this.theReturn?.origin?.address?.full_address,
        information: this.theReturn?.origin?.information,
        imported_id: this.theReturn?.order?.imported_id,
        weight: this.theReturn?.order?.packages[0]?.weight,
        volume: this.theReturn?.order?.packages[0]?.volume,
        n_packages: this.theReturn?.order?.packages[0]?.quantity,
        price: this.theReturn?.order?.packages[0]?.price,
        width: this.theReturn.order?.packages[0]?.width,
        length: this.theReturn.order?.packages[0]?.['length'],
        height: this.theReturn.order?.packages[0]?.height,
        description: this.theReturn.order.packages[0].info,
        zipcode: this.theReturn?.origin?.address.zip_code
      }
      this.internalLevels[this.theReturn.origin.address.country_code.toLowerCase()].display.map(level => {
        return this.form[`level${level}Text`] = this.theReturn.origin.address[`level${level}`]
      })
    },

    setInitialData () {
      this.is[this.type] = true //Seteamos true para saber que tipo de formulario es

      this.isAdmin = ['admin', 'superadmin'].includes(this.mySession.role)

      this.fields = [
        { fieldType: 'FieldSelect', name: 'company', label: this.$t('Empresa'), containerClass: 'col-sm-12 col-md-3 container-info', change: this.getCompanyData, validation: 'required', allowIdSearch: true },
        { fieldType: 'FieldSelect', name: 'destination', label: this.is.DCreate ?  this.$t('Lugar de retiro / Bodega') : this.$t('Lugar de destino / Bodega'), containerClass: 'col-sm-12 col-md-3 container-info', validation: 'required', dependency: 'company', change: this.changeWarehouse }
      ]
      this.fields2 = [
        { fieldType: 'FieldInput', name: 'name', label: this.$t('Nombre'), containerClass: 'col-sm-12 col-md-6 container-info', validation: 'required' },
        { fieldType: 'FieldInput', name: 'cellphone', label: this.$t('Celular'), containerClass: 'col-sm-12 col-md-6 container-info', prepend : {text: '+', color: '#BBBBBB'}, noZIndex: true },
        { fieldType: 'FieldInput', type: 'email', name: 'email', label: this.$t('Correo'), containerClass: 'col-sm-12 col-md-6 container-info', iconLeft: {icon: 'MailIcon', size: '20', stroke: 1.5, color: '#BBBBBB'}, noZIndex: true },
        { fieldType: 'FieldRadio', name: 'distributionType', label: this.$t('Tipo de distribución'), containerClass: 'col-sm-12 col-md-12 container-info', validation: 'required', align: 'h', options: this.optionsDistributionType, change: this.changeDistributionType, disabled: true },
        { fieldType: 'FieldTextarea', name: 'information', label: this.$t('Observaciones'), containerClass: 'col-sm-12 container-info' }
      ]
      this.fields3 = [
        { fieldType: 'FieldInput', name: 'imported_id', 
          label: this.$t('Nº de referencia'), containerClass: 'col-sm-12 col-md-6 container-info'
        },
        { name: 'line0', useSlot: true, skipLine: true, containerClass: 'col-sm-12' },
        { fieldType: 'FieldSelect', name: 'carrier',
          label: this.$t('Courier'), containerClass: 'col-sm-12 col-md-6 container-info', validation: 'required', change: this.changeCarrier, disabled: true
        }, //, searchOnType: { fx: this.getCourierCompany, nChars: 3, debounce: 600, persistSearch: true }
        { fieldType: 'FieldSelect', name: 'service', label: this.$t('Servicio'), containerClass: 'col-sm-12 col-md-6 container-info', validation: 'required', dependency: 'carrier'},
        { fieldType: 'FieldInput', name: 'weight', label: this.$t('Peso (kg)'), containerClass: 'col-sm-12 col-md-3 container-info', validation: 'required',
          change: this.is.DCreate ? this.setEstimatedPriceDelivery : null,
          specialVerification: this.is.DCreate ? this.replaceComma : null
        },
        { fieldType: 'FieldInput', name: 'volume', label: this.$t('Volumen (m³)'), containerClass: 'col-sm-12 col-md-3 container-info', validation: 'required',
          change: this.is.DCreate ? this.setEstimatedPriceDelivery : null,
          specialVerification: this.is.DCreate ? this.replaceComma : null
        },
        { fieldType: 'FieldInput', name: 'n_packages', label: this.$t('Nº de bultos'), containerClass: 'col-sm-12 col-md-3 container-info', validation: 'required' },
        { fieldType: 'FieldInput', name: 'price', label: this.$t('Valor producto'), containerClass: 'col-sm-12 col-md-3 container-info', validation: 'required', prepend: { text: '$', color: '#BBBBBB'}, noZIndex: true,
          specialVerification: this.is.DCreate ? this.changePrice : null,
          change: this.is.DCreate ? () => this.setEstimatedPriceDelivery(false) : null
        },
        { fieldType: 'FieldInput', name: 'height', label: this.is.DCreate ? this.$t('Alto (cm)') : this.$t('Alto'), containerClass: 'col-sm-12 col-md-4 container-info', 
          change: this.is.DCreate ? this.setEstimatedPriceDelivery : null,
          specialVerification: this.is.DCreate ? this.replaceComma : null
        },
        { fieldType: 'FieldInput', name: 'width', label: this.is.DCreate ? this.$t('Ancho (cm)') : this.$t('Ancho'), containerClass: 'col-sm-12 col-md-4 container-info', 
          change: this.is.DCreate ?  this.setEstimatedPriceDelivery : null,
          specialVerification: this.is.DCreate ? this.replaceComma : null
        },
        { fieldType: 'FieldInput', name: 'length', label: this.is.DCreate ? this.$t('Largo (cm)') : this.$t('Largo'), containerClass: 'col-sm-12 col-md-4 container-info',
          change: this.is.DCreate ? this.setEstimatedPriceDelivery : null,
          specialVerification: this.is.DCreate ? this.replaceComma : null
        },
        { fieldType: 'FieldTextarea', name: 'description', label: this.$t('Descripción del producto'), containerClass: 'col-sm-12 container-info', validation: 'required' }
      ]
      this.setFieldsSettings()

      if (this.services) {
        this.setSelectOptions('carrier', this.fields3, this.services.carriers)
        if (this.services.warehouses) this.setWarehouseValue()
        if (this.services.carriers) {
          this.form.carrier = this.services.carriers.filter(el => this.theReturn.carrier.name === el.carrier_name)[0]
          this.changeCarrier(null, this.form.carrier)
        }
      }
      if (this.theReturn?.status) this.verifyStatus()

      this.keyFormRender1++
      this.keyFormRender2++
      this.keyFormRender3++
    },
    setFieldsSettings() {
      const companyIndex = this.m_getElementIndex(this.fields, 'name', 'company')
      if (['seller', 'ecommerce'].includes(this.mySession.role)) {
        this.fields[companyIndex] = {
          ...this.fields[companyIndex],
          disabled: true,
          clearable: null,
          change: null
        }
        this.form.company = this.mySession.shipper.name1
        //Obtenemos los datos del seller ya que no permite busqueda de empresa
        if (this.is.DCreate) this.$store.dispatch('fetchService', { name: 'getCompanyInformation', params: { company_id: this.mySession.shipper.id } })
      }
      if (this.is.DCreate) {
        //Search para company
        this.fields[companyIndex].searchOnType = { fx: e => this.getCompaniesOnSearch(e), nChars: 3, debounce: 600, persistSearch: true }
        //Agregar el boton de nueva Bodega, informacion de compañia para los seller e ecommerce
        this.fields.push({name: 'newWarehouse', useSlot: true, containerClass: 'col-sm-12 col-md-6 pt-2' })

        if (this.m_haveSpecialPermission('create-deliveries-with-type-and-attributes')) {
          const indexS = this.m_getElementIndex(this.fields3, 'name', 'service')
          this.fields3.splice(
            indexS + 1, 0,
            { fieldType: 'FieldSelect', name: 'type', label: this.$t('Tipo'), containerClass: 'col-sm-12 col-md-6 container-info', change: this.setEstimatedPriceDelivery, dependency: 'carrier' }
          )
          this.setAttribute()
        }

        this.form.n_packages = 1
      }
      if (this.is.DInfo || this.is.RInfo) {
        this.fields.splice(
          companyIndex + 1,
          0,
          { fieldType: 'FieldInput', name: 'company_id', label: this.$t('ID empresa'), containerClass: 'col-sm-12 col-md-3 container-info', disabled: true }
        )
        this.fields.splice(
          companyIndex + this.is.RInfo ? 3 : 1,
          0,
          { fieldType: 'FieldInput', name: 'destination_address', label: this.$t('Dirección de destino'), containerClass: 'col-sm-12 col-md-6 container-info', disabled: true, validation: 'required'}
        )
        if (this.is.RInfo) {
          this.title1 = 'destinatario'
          this.title2 = 'origen'
          // this.optionsDistributionType.push({ id: 'agency', text: 'Agencía de carrier', value: false })
        }
      }

    },
    showEstimatedPrice(company) {
      const companyCustomization = company?.customization
      const mkpCustomization = company?.mkp_customization

      if (companyCustomization?.show_estimated_price === true) {
        delete company.customization
        delete company.mkp_customization
        return true
      }

      if (mkpCustomization?.show_estimated_price === true) {
        delete company.customization
        delete company.mkp_customization
        return true
      }

      delete company.customization
      delete company.mkp_customization
      return false
    },
    getCompaniesOnSearch(value) {
      const queryParams = {
        search_by: value,
        calls: 1
      }
      return this.baseService.callService('getCompaniesListData', queryParams)
        .then(({data}) => {
          const noMKPData = data.companies.filter(data => !data.marketplace).map(company => {
            return {
              ...company,
              text: company.name1,
              showEstimatedPrice: this.showEstimatedPrice(company)
            }
          })
          return noMKPData
        })
    },
    getCompanyData (name, value) {
      this.form = {
        ...this.form,
        destination: null,
        price: null,
        carrier: null,
        distributionType : null,
        service: null,
        attribute: null,
        amount_to_collect: null,
        zip_code: null,
        level1: null
      }
      this.optionsDistributionType = this.optionsDistributionType.filter(type => type.id !== 'intl')
      this.updateField(this.fields2, 'distributionType', this.optionsDistributionType, 'options')

      this.estimatedPriceDeliveryValue = null

      if (!value) {
        this.setInitialData()
        return
      }
      if (this.is.DCreate && value.phone_code)  this.updateField(this.fields2, 'cellphone', {text: `+${value.phone_code}`, color: '#BBBBBB'}, 'prepend')
      const indexVolume = this.m_getElementIndex(this.fields3, 'name', 'volume')
      const index = this.m_getElementIndex(this.fields3, 'name', 'weight')
      if (value.country_code.toUpperCase() !== 'CO' && indexVolume === -1) {
        this.fields3.splice(index + 1, 0, { fieldType: 'FieldInput', name: 'volume', label: this.$t('Volumen (m³)'), containerClass: 'col-sm-12 col-md-3 container-info', validation: 'required',
          change: this.is.DCreate ? this.setEstimatedPriceDelivery : null,
          specialVerification: this.is.DCreate ? this.replaceComma : null
        })
      } else if ((value.country_code.toUpperCase() === 'CO' && !value.mkp_customization?.replace_null_volume) && indexVolume !== -1) {
        this.fields3.splice(indexVolume, 1)
      }
      this.updateField(this.fields2, 'distributionType')
      this.setFormUpdates(value)

      const isAdmin = ['admin', 'superadmin'].includes(this.mySession.role)
      const user_access = this.$session.get('cas_user')?.user_access
      if (isAdmin || (!isAdmin && value?.customization && user_access?.show_stimated_price && user_access?.employee_comp_custom)) {
        this.showPrice = true
      }
      // if (value.id === 3063) {
      if (!!value?.customization?.can_intl_delivery) {
        this.optionsDistributionType.push({ id: 'intl', text: 'Envío internacional', value: false })
        this.updateField(this.fields2, 'distributionType', this.optionsDistributionType, 'options')
      }
      const services = [
        { name: 'getCouriers', queryParams: {shipper_id: value?.id} },
        { name: 'getCourierConfiguration', params: {company_id: value?.id} },
        { name: 'getWarehouseCompanies', params: {company_id: value?.id}, queryParams: { limit: 999 } }
      ]
      if (
        value?.country_code?.toLowerCase() !== 'mx' && this.levelCountry !== value?.country_code //Evitar llamar tanto esta APi que aveces es lenta
      ) services.push({ name: 'getLevels', params: {country: value?.country_code?.toLowerCase()} })
      this.levelCountry = value?.country_code

      this.$store.dispatch('fetchMultipleServices', { 
        services,
        onSuccess: () => {
          this.companyId = value.id
          this.closeAlertLoading()
          this.changeDistributionType('_', { id: 'home' })
        },
        onError: this.closeAlertLoading
      })
      if (this.showLoadingAlert) {
        setTimeout(() => { //Para que se cierre el listado de companies
          this.$loadingAlert({text: '<i class="fa fa-info-circle"></i> Extrayendo información de la empresa...', color: 'primary', position: 'bottom-start'})
        }, 0)
      }
    },
    setFormUpdates(value) {
      const currency = this.m_changeTextI18n(value.country_code || value.country.code, 'currency_country')
      this.updateField(this.fields, 'destination')
      this.updateField(this.fields3, 'carrier')
      this.updateField(this.fields3, 'price', this.m_changeTextI18n(value.country_code || value.country.code, 'product_value'), 'label')
      this.updateField(this.fields3, 'price', { text: currency, color: '#BBBBBB'}, 'prepend')

      this.checkValidation()

      if (!this.form.distributionType) {
        this.form.distributionType = this.optionsDistributionType[0]
      }
      this.updateField(this.fields2, 'distributionType', false)
    },
    getCourierCompany(value) {
      const queryParams = {
        text: value,
        shipper_id: parseInt(this.form?.company?.id)
      }
      return this.baseService.callService('getCouriers', queryParams)
        .then(response => {
          return response.data.carriers.map(el => ({...el, text: el.name}))
        })
        .catch(error => console.error(error))

    },
    changeCarrier (name, value) {
      this.form = {
        ...this.form,
        service: null,
        attribute: null,
        type: null
      }
      this.setSelectOptions('service', this.fields3, [])
      if (this.is.DCreate) {
        //En la creacion puede que para algunos carrier sea obligatorio
        const validationName = (value?.fields_customization?.dimensions && value?.fields_customization?.dimensions.includes('required')) ? 'required' : ''
        const indexComp = this.fields3.findIndex(el => el.name === 'infoCompensation')
        if (value?.carrier_name === 'CHILEXPRESS' && value?.max_compensation_amount) {
          const indexS = this.fields3.findIndex(el => el.name === 'service')
          this.fields3.splice(indexS + 1, 0, { name: 'infoCompensation', useSlot: true, containerClass: 'col-sm-12 container-info' })
        } else if (indexComp !== -1) {
          this.fields3.splice(indexComp, 1)
        }

        ['height', 'width', 'length'].forEach(fieldName =>  {
          this.updateField(this.fields3, fieldName, validationName, 'validation')
        })
        
        this.showAmountToCollect()
      }

      if (!value) {
        this.setSelectOptions('service', this.fields3, [])
        return
      }
      let services = []
      if (this.is.DCreate) {
        services = value.services.filter(serv => serv?.is_delivery
        ).filter(
          service => (service.contract_type !== null && service.contract_type === value.accounts[0].contract_type) || service.contract_type === null
        ).filter(
          service => !value.accounts[0].blocked_service_ids.includes(service.id)
        )
        if (!(this.form.company?.marketplaces === 'Walmart Chile' && value.code.toLowerCase() === 'chx')) {
          services = services.filter(
            service => !['ampliadoPlus', 'encomiendaGrandeExtendido', 'externo', 'extendido', 'ampliado'].includes(service.code)
          )
        }
      } else if ((this.is.RInfo || this.is.RCreate)) {
        services = value.services.filter(serv => serv?.is_return)
      }
      if (name === 'carrier') this.form.service = services[0]
      this.updateField(this.fields3, 'service', services, 'options')
      if (value?.attributes) {
        const attributes = this.filterAttributes(value)
        this.updateField(this.fields3, 'attribute', attributes, 'options')
      }
      if (this.m_haveSpecialPermission('create-deliveries-with-type-and-attributes') && value?.types) this.updateField(this.fields3, 'type', value.types, 'options')
    },
    filterAttributes(carrier) { // Momentaneo hasta que backend agregue el hidden en los atributos
      let attributeOpt = carrier.attributes
      if (carrier?.code && ['MUCO', 'COO', 'CO99', 'TCC', 'ITR', 'QUIC'].includes(carrier?.code)) {
        attributeOpt = attributeOpt.filter(el => el.code.indexOf('cod') !== -1)
        attributeOpt = attributeOpt.filter(el => el.code.indexOf('codEF') === -1)
      }
      if (!['admin'].includes(this.mySession.role) && carrier?.code.toLowerCase() === 'fdx') attributeOpt = attributeOpt.filter(att => att.code !== 'volume')
      return attributeOpt
    },
    changeCarrierAgency (name, value) {
      this.form = {
        ...this.form,
        carrier: value,
        agency: null
      }
      this.infoAgency = null
      this.changeCarrier(null, value)
      if (value !== null) this.getPudosCarriersOnSearch(null)
    },
    checkValidation () {
      const { customization } = this.form?.company
      if (
        this.is.DCreate && customization &&
        Array.isArray(customization.optional_fields) &&
        Array.isArray(customization.optional_fields).length
      ) {
        const fields3 = this.fields3
        const updateValidation = (fieldName, codeType) => {
          const index = this.m_getElementIndex(fields3, 'name', fieldName)
          fields3[index].validation = customization.optional_fields.includes(`${codeType}_code`) ? null : 'required'
        }

        updateValidation('carrier', 'carrier')
        updateValidation('service', 'service')

        this.keyFormRender3++
      }
    },
    changePrice(value) {
      if (!value) return value
      const countryCode = Number(this.form.company?.country_id)

      // Condiciones de mak para CL, PE, MX
      if ([2, 3, 4].includes(countryCode)) {
        const isCL = countryCode === 3
        const str = isCL ? ',' : '.'
        const decimal = isCL ? 3 : 2
        const addDecimals = value.includes(str)
        const lang = isCL ? 'es-ES' : 'en-US'
        const options = {
          style: 'decimal',
          useGrouping: true,
          maximumFractionDigits: addDecimals ? decimal : 0,
          minimumFractionDigits: 0
        }
        if (addDecimals) {
          const parts = value.split(str)
          const decimalPart = parts[1].substring(0, decimal) // Tomamos solo los primeros tres dígitos decimales
          value = `${parts[0]}${str}${decimalPart}`
          if (parts[1].length > decimal) this.keyFormRender3++
        }
        const number = isCL ? parseFloat(value.replace(/\./g, '').replace(',', '.')) : parseFloat(value.replace(/,/g, ''))
        return number.toLocaleString(lang, options)
      }

      else {
        const cleanedValue = value.replace(/\D/g, '')
        return cleanedValue.replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
    },
    setEstimatedPriceDelivery() {
      const { price, carrier, service, origen, company, weight, volume, destination, ...rest} = this.form
      const co_code = company?.country_code.toLowerCase()
      const weig = weight
      const vol = volume
      const queryParams = {
        carrier: carrier?.code || null,
        service: service?.code || null,
        origen: null,
        destino: null,
        weight: Number(weig) || null,
        fecha: this.$options.filters.dbDateUTCToGMTlocal(new Date(), 'YYYY-MM-DD HH:mm:ss'),
        show_carriers: true,
        declared_value: price || null,
        country: co_code || null
        // volume: vol || null
      }
      if (queryParams.country !== 'co') queryParams.volume = vol || null
      const placeObj = co_code === 'mx' ? rest.level4 : this.ObjPlace
      if (!!destination && !!placeObj) {
        const customizationFrom = JSON.parse(destination?.place_customization | {}) || {}
        const customizationTo = JSON.parse(placeObj?.customization | {}) || {}
        const warehousePlaceData = {
          id: destination?.id,
          name: destination?.place_name,
          code: customizationFrom?.cod_dane || customizationFrom?.ubigeo || destination?.code || null,
          customization: customizationFrom
        }
        if ([3, 4, 5, 6].includes(company.country_id)) {
          queryParams.origen = warehousePlaceData.code || null
          queryParams.destino = customizationTo?.cod_dane || customizationTo?.ubigeo || placeObj?.code || null
        } else if ([2].includes(company.country_id)) {
          queryParams.origen = customizationFrom?.zip_code || null
          queryParams.destino = customizationTo?.zip_code || null
          queryParams.is_from_postal_code = true
          queryParams.is_to_postal_code = true
        } else { // Solo place
          queryParams.origen = warehousePlaceData.name || null
          queryParams.destino = placeObj?.name || null
        }
      }
      //Valido los campos que si o si necesitaré
      const validPayload = Object.keys(queryParams).every(key => queryParams[key] !== null)
      if (!validPayload) return null
      // Y luego agrego los demas campos opcionales
      queryParams['length'] = rest['length'] || null
      queryParams.height = rest.height || null
      queryParams.width = rest.width || null
      // Si se ha agregado información en los campos length/height/width calculamos el Peso Volumétrico
      if (rest['length'] && rest.width && rest.height) {
        // Para calcular el precio volumétrico multiplicamos length/height/width y lo dividimos entre 4000
        let volumetricWeight = parseInt(rest['length']) * parseInt(rest.width) * parseInt(rest.height) / 4000
        // Validamos cual peso es mayor(Peso o Peso Volumétrico)
        volumetricWeight = volumetricWeight > parseInt(weig) ? volumetricWeight : parseInt(weig)

        // Seteamos en el payload.weight el valor devuelto
        queryParams.weight = volumetricWeight
      }
      this.$store.dispatch('fetchService', {
        name: 'findStimatedPrice',
        queryParams,
        onSuccess: (resp) => {
          if (resp.data.status === 'failed') this.estimatedPriceDeliveryValue = 'No es posible estimar el envío'
          else this.estimatedPriceDeliveryValue = resp.data.pricing
        },
        onError: (err) => this.estimatedPriceDeliveryValue = 'No es posible estimar el envío',
        hideAlert: true
      })
    },
    setDistributionType () {
      if (this.theReturn?.origin?.type === 'pudo') {
        this.form.distributionType = this.optionsDistributionType[1]
        // this.form.pudo = this.services?.pudos.filter(el => this.theReturn.origin.code === el.code)[0]
        this.infoPudo = this.form.pudo.address?.full_address
      } else if (this.theReturn.origin.type === 'agency') {
        this.form.distributionType = this.optionsDistributionType[2]
        // this.form.agency = this.services.agencies.filter(el => this.theReturn.origin.code === el.code)[0]
        this.infoAgency = this.form.agency.address.full_address
      } else {
        this.form.distributionType = this.optionsDistributionType[0]
      }
      this.changeDistributionType('', this.form.distributionType)
      this.keyFormRender2++
    },
    setAttribute () {
      const indexA = this.m_getElementIndex(this.fields3, 'name', 'attribute')
      if (indexA === -1) {
        const indexW = this.m_getElementIndex(this.fields3, 'name', 'weight')
        this.fields3.splice(
          indexW, 0,
          { fieldType: 'FieldSelect', name: 'attribute', label: this.$t('Atributo'), containerClass: 'col-sm-12 col-md-6 container-info', dependency: 'carrier', change: this.changeAttibutte, multiple: true, specialName: true }
        )
      }
    },
    changeAttibutte() {
      this.showAmountToCollect() //Llamamos para segunda condicional
      this.setEstimatedPriceDelivery()
    },
    showAmountToCollect() {
      const indexAt = this.form.attribute?.findIndex(item => item && item.code && item.code.startsWith('cod'))
      const indexA = this.m_getElementIndex(this.fields3, 'name', 'amount_to_collect')
      if ((['SVPE', 'MX99', 'URP', 'UBN', 'BLX', 'MXYG', 'URPB', 'PEMV'].includes(this.form?.carrier?.carrier_code) || this.form?.company.country_code?.toLowerCase() === 'co') && (!!this.form.attribute && indexAt !== -1)) {
        //Si estan los elementos entonces se agrega el "monto a recolectar"
        if (indexA === -1) {
          const indexD = this.m_getElementIndex(this.fields3, 'name', 'description')
          this.fields3.splice(
            indexD, 0,
            { fieldType: 'FieldInput', name: 'amount_to_collect', label: this.$t('Monto a recolectar'), containerClass: 'col-sm-12 col-md-6 container-info' }
          )
        } else {
          this.fields3.splice(indexA, 1)
        }
      } else if (indexA !== -1) {
        this.fields3.splice(indexA, 1)
      }
    },
    changePudo (name, value) {
      this.infoPudo = null
      this.ObjPlace = null
      if (!!value) {
        this.infoPudo = `${value.address.full_address}`
        this.ObjPlace = {
          id: value.id,
          name: value.name,
          code: value.code
          // customization: value.place_customization
        }
      }
      this.setEstimatedPriceDelivery()
      this.keyFormRender2++
    },
    getPudosCompanyOnSearch (value = null) {
      const queryParams = {
        'country.id': this.form.company.country_id,
        'address.place.level1.id': this.form[`level${this.internalLevels[this.form.company.country_code.toLowerCase()].required}`].id,
        'owner.organization.id': this.form.company.id,
        'owner.type': 'Seller,Organization'
      }
      if (value !== null) queryParams['name__like__or__code__like'] = value
      const service = this.is.DCreate ? 'getPudosForType' : 'getPudosFlash'
      this.baseService.callService(service, queryParams)
        .then(resp => {
          const pudosCompany = resp.data.map(pudo => {
            pudo.text = `${pudo.name} (${pudo.code})`
            return pudo
          })
          this.setSelectOptions('pudo', this.fields2, pudosCompany)
        })
    },

    changeAgency (name, value) {
      value === null ?  this.infoAgency = null : this.infoAgency = `${value.address.full_address}`
      this.keyFormRender2++
    },
    getPudosCarriersOnSearch (value = null) {
      const queryParams = {
        'country.id': this.form.company.country_id,
        'address.place.level1.id': this.form[`level${this.internalLevels[this.form.company.country_code.toLowerCase()].required}`].id,
        'owner.type': 'Courier',
        'carrier.code': this.form.carrier.code
      }
      if (value !== null) queryParams['name__like__or__code__like'] = value
      const service = this.is.DCreate ? 'getPudosForType' : 'getAgenciesFlash'
      return this.baseService.callService(service, queryParams)
        .then(resp => {
          const pudosCarriers = resp.data.map(el => {
            el.text = `${el.name} (${el.code})`
            return el
          })
          this.setSelectOptions('agency', this.fields2, pudosCarriers)
        })
    },

    changeWarehouse (name, value) {
      if (value === null || value === undefined) {
        this.form.destination_address = null
      } else if (this.is.DCreate) {
        this.form.destination_address = value?.full_address
        this.setEstimatedPriceDelivery()
      } else {
        this.form.destination_address = value?.address?.full_address
      }
      this.keyFormRender1++
    },

    setWarehouseValue () {
      if (this.theReturn.destination.code === '_NO_WAREHOUSE_') {
        this.form.destination = {
          ...this.theReturn.destination,
          text: 'NO BODEGA'
        }
        this.form.destination_address = this.theReturn.destination.address.full_address
      } else {
        const warehouse = this.services.warehouses.filter(el => this.theReturn.destination.code === el.code)[0]
        if (warehouse === undefined) {
          this.form.destination = { ...this.theReturn.destination, text: this.theReturn.destination.code }
        } else this.form.destination = warehouse
        this.changeWarehouse(null, this.form.destination)
      }
    },
    changeDistributionType (name, value) {
      let country = {}
      let country_code = ''
      this.updateField(this.fields3, 'carrier', false)
      if (name === 'distributionType') {
        this.form = {
          ...this.form,
          carrier: null,
          service: null,
          attribute: null,
          carrier_agency: null,
          zipcode: null,
          pudo: null,
          agency: null,
          full_address: null
        }
        this.changePudo('', null)
        this.changeCarrierAgency('', null)
        if (this.selectorsLevel) Object.keys(this.selectorsLevel).map(level => this.form[level] = null)
      }
      if (this.is.RInfo) {
        country_code = this.theReturn?.destination?.address?.country_code.toLowerCase()
      } else {
        country_code = this.form?.company?.country_code.toLowerCase()
      }
      country = {
        code: country_code,
        required: this.internalLevels[country_code]?.required,
        display: this.internalLevels[country_code]?.display
      }
      this.fields2 = this.fields2.filter(el => !['pudo', 'agency', 'carrier_agency'].includes(el.name)).filter(el => el.name.indexOf('level') === -1 && !['place', 'place_1', 'full_address', 'zipcode', 'infoPudo', 'infoAgency', 'intl_country'].includes(el.name))
      let index = this.fields2.findIndex(el => el.name === 'distributionType')
      if (value.id === 'intl') {
        this.fields2.splice(index + 1, 0, 
          { fieldType: 'FieldSelect', name: 'intl_country', label: this.$t('País'), containerClass: 'col-sm-12 col-md-6 container-info', options: this.countries,
            validation: 'required', change: this.changeIntlCountry },
          { fieldType: 'FieldInput', name: 'full_address', label: this.$t('Dirección'),  containerClass: 'col-sm-12 col-md-12 col-lg-12 container-info', validation: 'required' }
        )
      } else {
        if ((this.is.RInfo && this.theReturn?.destination?.address?.country_code.toUpperCase() === 'MX') || (this.is.DCreate && this.form?.company?.country_code?.toLowerCase() === 'mx')) {
          this.fields2.splice(index + 1, 0,
            { fieldType: 'FieldSelect', name: 'zipcode', label: this.$t('Código postal'), containerClass: 'col-sm-12 col-md-6 container-info', searchOnType: { fx: this.searchZipCode, nChars: 3, debounce: 300 }, validation: 'required', change: this.handleSelectZipcode }
          )
          index++
        }
        if (value.id === 'pudo') {
          this.fields2.splice(index + 1, 0, {
            fieldType: 'FieldSelect', name: 'pudo', label: this.$t('Punto de retiro'), containerClass: 'col-sm-12 col-md-6 container-info', change: this.changePudo,
            validation: 'required', dependency: `level${country.required}` // searchOnType: { fx: this.getPudosCompanyOnSearch, nChars: 3, debounce: 300 },
          },
          { name: 'infoPudo', useSlot: true, containerClass: 'col-sm-12 col-md-6 container-info' }
          )
          //Condicionado por el momento solo a informacion de devolucion
          if (this.is.RInfo) {
            this.setSelectOptions('pudo', this.fields2, this.services.pudos)
          }
        } else if (value.id === 'agency') {
          this.updateField(this.fields3, 'carrier', true)
          this.fields2.splice(index + 1, 0, 
            { fieldType: 'FieldSelect', name: 'carrier_agency', label: this.$t('Courier'), containerClass: 'col-sm-12 col-md-6 container-info', options: this.courierCompanies,
              validation: 'required', change: this.changeCarrierAgency, dependency: `level${country.required}` },
            { fieldType: 'FieldSelect', name: 'agency', label: this.$t('Agencia de courier'), containerClass: 'col-sm-12 col-md-6 container-info', change: this.changeAgency,
              validation: 'required', dependency: 'carrier_agency' }, // searchOnType: { fx: this.getPudosCarriersOnSearch, nChars: 3, debounce: 300 },
            { name: 'infoAgency', useSlot: true, containerClass: 'col-sm-12 col-md-6 container-info' }
          )
          if (this.is.RInfo) {
            this.setSelectOptions('agency', this.fields2, this.services.agencies)
          }
        } else if (value.id === 'home') {
          if (
            (this.is.RInfo && this.theReturn?.destination?.address?.country_code.toUpperCase() !== 'MX')
            ||
            (this.is.DCreate && this.form?.company?.country_code?.toLowerCase() !== 'mx')) {
            this.fields2.splice(index + 1, 0,
              { fieldType: 'FieldInput', name: 'zipcode', label: this.$t('Código postal'), containerClass: 'col-sm-12 col-md-6 container-info' },
              { fieldType: 'FieldInput', name: 'full_address', label: this.$t('Dirección'),  containerClass: 'col-sm-12 col-md-12 col-lg-12 container-info', validation: 'required' }
            )
          }
          else {
            this.fields2.splice(index + 1, 0,
              { fieldType: 'FieldInput', name: 'full_address', label: this.$t('Dirección'),  containerClass: 'col-sm-12 col-md-12 col-lg-12 container-info', validation: 'required' }
            )
          }
          this.form.zipcode = null
        }
      }
      if (value.id !== 'intl') this.addPlacesLevelFields(country, this.levels)
      this.keyFormRender2++
    },
    addPlacesLevelFields (country, levels, fromIntl = false) {
      this.selectorsLevel = this.$generatePlacesLevels(this.$generateArrayPlacesFromTree(levels), Math.max(country.required), country.display)
      const texts = this.$i18nImport(`${country.code?.toLowerCase()}.es`)[`${country.code.toLowerCase()}.es`]
      let indexSplice = fromIntl ? this.fields2.findIndex(el => el.name === 'intl_country') + 1 : this.fields2.findIndex(el => el.name === 'distributionType') + 1
      if (country.code.toUpperCase() === 'MX') {
        indexSplice = this.fields2.findIndex(el => el.name === 'zipcode') + 1
        this.selectorsLevel = { level4: this.selectorsLevel.level4, level1: this.selectorsLevel.level1, level2: this.selectorsLevel.level2}
      }
      Object.keys(this.selectorsLevel).map((key, index) => {
        if (key.endsWith('NaN')) return  //--------->    Validar que sucede porque pasa esto OJO    <---------
        if (country.code.toUpperCase() === 'MX') {
          const myField = { name: key, fieldType: 'FieldSelect', label: texts[`Place_${key}`], containerClass: 'col-sm-12 container-info col-md-6' }
          if (key === 'level4') {
            myField.dependency = 'zipcode'
            myField.useLabel = true
            myField.change = this.changeOnLevel4
          } else {
            myField.dependency = true
          }
          myField.validation = (country.required || []).includes(parseInt(key.split('level')[1])) ? 'required' : ''
          const indexFields = this.fields2.findIndex(el => el.name === myField.name)
          if (indexFields === -1) this.fields2.splice(indexSplice, 0, myField)
          if (this.form[`${key}Text`]) this.form[key] = { text: this.form[`${key}Text`], id: this.form[`${key}Text`] }
        } else {
          const myField = { fieldType: 'FieldSelect', name: key, label: texts[`Place_${key}`], containerClass: 'col-sm-12 container-info col-md-6', options: this.selectorsLevel[key].sort((a, b) => a.name > b.name ? 1 : -1) }
          if (index !== 0) {
            myField.dependency = Object.keys(this.selectorsLevel)[index - 1]
          }
          if (index !== Object.keys(this.selectorsLevel).length - 1) {
            myField.change = (name, value) => this.changePlaceLevel(name, value, Object.keys(this.selectorsLevel)[index + 1])
          } else {
            myField.change = (name, value) => this.changePlaceLevel(name, value, Object.keys(this.selectorsLevel)[index + 1])
          }
          myField.validation = (country.required || []).includes(parseInt(key.split('level')[1])) ? 'required' : ''
          const indexFields = this.fields2.findIndex(el => el.name === myField.name)
          if (indexFields === -1) {
            this.fields2.splice(indexSplice, 0, myField)
          }
          this.form[key] = this.selectorsLevel[key].filter(e => e.text === this.form[`${key}Text`])[0]
        }
        indexSplice++
      })
    },
    async changeIntlCountry (name, value) {
      this.fields2 = this.fields2.filter(el => !['pudo', 'agency', 'carrier_agency'].includes(el.name)).filter(el => el.name.indexOf('level') === -1 && !['place', 'place_1', 'level1', 'full_address', 'zipcode', 'infoPudo', 'infoAgency'].includes(el.name))
      if (!value) {
        this.setSelectOptions('carrier', this.fields3, this.courierCompanies)
        return
      }
      const country = {
        code: value.code,
        required: this.internalLevels[value.code.toLowerCase()]?.required,
        display: this.internalLevels[value.code.toLowerCase()]?.display
      }
      const carriersFiltered = this.courierCompanies.filter(carrier => carrier.countries.map(coun => coun.code.toLowerCase()).includes(country.code.toLowerCase()))
      this.setSelectOptions('carrier', this.fields3, carriersFiltered)
      let levels = []
      if (country.code === 'MX') {
        const index = this.fields2.findIndex(el => el.name === 'intl_country')
        this.fields2.splice(index + 1, 0,
          { fieldType: 'FieldSelect', name: 'zipcode', label: this.$t('Código postal'), containerClass: 'col-sm-12 col-md-6 container-info', searchOnType: { fx: this.searchZipCode, nChars: 3, debounce: 300 }, validation: 'required', change: this.handleSelectZipcode }
        )
      } else {
        levels = await this.baseService.callService('getLevels', {}, { country: country?.code?.toLowerCase()})
          .then(resp => { return resp.data.level1 })
      }
      this.addPlacesLevelFields(country, levels, true)
    },
    orderFields() {
      if (this.form?.company?.country_code?.toLowerCase() !== 'mx') return
      const indexZ = this.fields2.findIndex(item => item.name === 'zipcode')
      const indexC = this.fields2.findIndex(item => item.name === 'level4')
      this.fields2[indexC] = {
        ...this.fields2[indexC],
        dependency: null,
        change: this.handleChangeColony
      }
      const colonyObject = this.fields2.splice(indexC, 1)[0]
      const zipcodeObject = this.fields2.splice(indexZ - (indexZ > indexC ? 1 : 0), 1)[0]
      this.fields2.splice(4, 0, zipcodeObject)
      this.fields2.splice(5, 0, colonyObject)
      const indexE = this.fields2.findIndex(item => item.name === 'level1')
      const indexM = this.fields2.findIndex(item => item.name === 'level2')
      this.fields2[indexE] = {
        ...this.fields2[indexE],
        disabled: true,
        fieldType: 'FieldInput'
      }
      this.fields2[indexM] = {
        ...this.fields2[indexM],
        disabled: true,
        fieldType: 'FieldInput'
      }
    },
    changePlaceLevel (name, value, next) {
      if (this.form.distributionType.id === 'agency') {
        this.form.carrier_agency = null
        this.form.agency = null
        this.changeCarrierAgency('', null)
      } 
      if (!value) {
        this.form[next] = null
        this.ObjPlace = null
        return
      }
      const index = this.fields2.findIndex(el => el.name === next)
      if (next && index !== -1) this.fields2[index].options = value[next].sort((a, b) => a.name > b.name ? 1 : -1)
      if (this.is.DCreate && index === -1) {
        this.baseService.callService('getPlaceByPlatId', {}, {platform_id: value?.platform_id})
          .then(resp => {
            this.ObjPlace = resp.data
            this.setEstimatedPriceDelivery()
            if (this.form.distributionType.id === 'pudo') {
              this.getPudosCompanyOnSearch(null)
            } 
          })
          .catch(err => {
            console.error(err)
          })
      } else {
        this.ObjPlace = null
      }
    },
    searchZipCode(val) {
      const params = {
        country_id: 2
      }
      const queryParams = {
        str: val
      }
      return this.baseService.callService('getZipcodeCompanies', queryParams, params)
        .then(resp => {
          const response = resp.data.zip_codes.map(val => ({'name': val, 'id': val}))
          response.map((row) => {
            Object.keys(row).forEach((key) => {
              return row[key] = `${row[key]}`
            })
            row.text = row.name
          })
          return response
        })
        .catch(err => {
          return err
        })
    },
    handleSelectZipcode(name, value) {
      this.form = {
        ...this.form,
        level1: null,
        level2: null,
        level4: null
      }
      this.changeOnLevel4('', this.form.level4)
      if (!value) {
        this.keyFormRender2++
        return
      }
      const params = {
        country_id: this.form?.company?.country_id
      }
      const queryParams = {
        'str': '',
        'zip-code': value.name
      }
      this.baseService.callService('getPlacesCompanies', queryParams, params)
        .then(resp => {
          const response = resp.data.map(place => ({...place, text: place.name}))
          this.setSelectOptions('level4', this.fields2, response)
        })
        .catch(err => {
          console.error(err)
        })
    },
    handleChangeColony(_, val) {
      this.form.level1 = val?.level2?.name || null
      this.form.level2 = val?.level3?.name || null
      this.formRenderLogistic2++
    },
    changeOnLevel4(name, value) {
      this.form = {
        ...this.form,
        level1: value?.level1 || null,
        level2: value?.level2 || null,
        carrier_agency: null,
        agency: null
      }
      this.changeCarrierAgency('', null)
      if (!!value) Object.keys(this.selectorsLevel).filter(level => level !== name).map(level => this.form[level] = { ...value[level], text: value[level].name })
      this.keyFormRender2++
    },
    async validateForm () {
      const value1 = await this.$refs.formRenderLogistic1.onlyCheckForm()
      const value2 = await this.$refs.formRenderLogistic2.onlyCheckForm()
      const value3 = await this.$refs.formRenderLogistic3.onlyCheckForm()
      return value1 && value2 && value3
    },
    async sendForm () {
      const checkForm = await this.validateForm()
      if (checkForm === false) return
      switch (this.type) {
      case 'DCreate':
        this.updateButtonForm(true)
        this.$emit('onSave', this.form)
        break
      case 'RCreate':
        break
      case 'DInfo':
        break
      case 'RInfo':
        this.sendFormRInfo()
        break
      default:
        break
      }
    },
    sendFormRInfo() {
      const payload = this.buildPayload(this.form)
      if (payload.warehouse === '_NO_WAREHOUSE_') {
        this.$alert('No es posible editar debido a que el destino debe ser una bodega. Si es necesario, debe agregarla al listado de bodegas de su empresa')
        return
      }
      if (payload.id) {
        this.disabled.sendForm = true
        this.show.loadingSendForm = true
        const data = {
          name: 'updateReturn',
          queryParams: payload,
          params: { shipper_id: payload.shipper.id, id: payload.id }
        }
        this.$store.dispatch('fetchService', data)
      }
    },
    buildPayload (form) {
      const delivery = {
        type: form.type,
        carrier: {
          code: form.carrier.code || null,
          service: form.service.code || null
        },
        order: {
          imported_id: form.imported_id || '',
          packages: [{
            weight: form.weight || null,
            volume: form.volume || null,
            width: form.width || null,
            height: form.height || null,
            length: form.length || null,
            price: form.price || null,
            quantity: form.n_packages || null,
            info: form.description || null
          }]
        },
        shipper: {
          code: form.shipper.code,
          id: form.shipper.id
        }
      }
      if (form.id) delivery.id = form.id
      if (delivery.type === 'delivery') {
        delivery.destination = {
          type: form.destination.type,
          code: form.destination.code
        }
      } else {
        delivery.destination = {
          type: form.destination.type,
          code: form.destination.code
        }
      }
      if (['home', 'intl'].includes(form.distributionType.id)) {
        const levels = Object.keys(form).filter(key => ['level1', 'level2', 'level3', 'level4'].includes(key))
        delivery.origin = {
          type: form.distributionType.id,
          code: null,
          contact: {
            name: form.name,
            email: form.email,
            phone: form.phone
          },
          address: {
            full_address: form.full_address,
            zip_code: form.zipcode || null,
            information: form.information
          }
        }
        levels.map(level => form[`${level}`] ? delivery.origin.address[`${level}`] = form[`${level}`].name : delivery.origin.address[`${level}`] = null)
      } else {
        delivery.origin = {
          type: form.distributionType.id,
          code: form[form.distributionType.id].code,
          address:  form[form.distributionType.id].address,
          contact: {
            name: form.name,
            email: form.email,
            phone: form.phone
          }
        }
      }
      return delivery
    },
    verifyStatus () {
      if (this.theReturn.status.id === 4) {
        [this.fields, this.fields2, this.fields3].map((x, index) => {
          x.map(y => {
            if (y.name === 'company' || y.name === 'company_id' || y.name === 'destination_address') {
              y.disabled = true
            } else {
              y.disabled = false
            }
          })
        })
        this.disabled.sendForm = false
      } else {
        [this.fields, this.fields2, this.fields3].map(x => {
          x.map(y => (y.disabled = true))
        })
        this.disabled.sendForm = true
      }
      this.keyFormRender2++
      this.keyFormRender3++
    },
    setSelectOptions (name, fields, options) {
      const index = fields.findIndex(el => el.name === name)
      if (index !== -1) {
        fields[index].options = options
      }
      this.keyFormRender1++
      this.keyFormRender2++
      this.keyFormRender3++
    },
    updateField (fields, name, value = true, prop = 'disabled') {
      const index = fields.findIndex((el) => el.name === name)
      fields[index][prop] = value
    },
    replaceComma(value) {
      return value.replace(/\./g, ',')
    },
    closeAlertLoading() {
      Swal.update({customClass: {}})
      Swal.close()
    },
    updateButtonForm(bol) {
      this.disabled.sendForm = bol
    },
    isCenco(session) {
      return session?.special_profile === 'cenco'
    },
    checkPrice(price, country_id) {
      const countryId = country_id
      const limits = this.isCenco(this.$session.get('cas_user')) ?  this.maxPricesByCountry[countryId]?.profile.cenco : this.maxPricesByCountry[countryId]?.profile.normal
 
      const priceValue = parseInt((price?.toString()?.replace(/\./g, '')) || 0)

      if (priceValue > parseInt(limits?.warning)) {
        this.$warning('El precio del producto supera el valor normal de un envío', '', '!Advertencia!')
      }
      if (limits?.alert && priceValue > parseInt(limits?.alert)) {
        this.$alert('El precio del producto supera el valor normal de un envío', '', 'Error')
        this.$refs.createDeliveryButton.disabled = true
      } else {
        this.$refs.createDeliveryButton.disabled = false
      }
    }
  }
}
</script>

<style>
.header-group {
  color: black !important;
  font-size: 1.1rem;
  font-weight: bold;
  text-transform: capitalize;
}
</style>