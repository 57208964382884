<template>
  <div class="ticket-list">
    <b-card>
      <form-render
        ref="printedForm"
        :key="keyFormRender"
        class="mb-2"
        :form.sync="formFilter"
        :fields="fields"
        @send="filterForm"
        :buttonSend="buttonSend"
        containerButtonsClass="col-sm-12 col-md-8 col-lg-6 container-button mt-2"
      >
        <template #buttons>
          <b-dropdown
            class="dropdown-buttons-print"
            v-b-tooltip.hover
            :title="$t('Más opciones')"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="light"
          >
            <template #button-content><feather-icon icon="SettingsIcon" /></template>
            <b-dropdown-item @click="cleanFilter">{{$t('Limpiar filtros')}}</b-dropdown-item>
            <b-dropdown-item v-b-modal.modalAvancedFilterDeliveries>{{$t('Búsqueda avanzada')}}</b-dropdown-item>
          </b-dropdown>
          <b-button variant="warning" @click="openModal('modalMultiSearch')">Multiselección</b-button>
          <b-form-checkbox
            class="custom-switch-success push-right"
            :checked="formFilter.sameday"
            switch
            v-model="formFilter.sameday"
            @change="filterForm(formFilter)"
          >
            {{$t('Servicio Sameday')}}</b-form-checkbox
          >
        </template>
      </form-render>
      <div v-show="!loading.first" class="col-md-12">
        <span v-b-tooltip="!selectedRows.length ? $t('msg-alert-disabled-panel') : ''">
          <b-button variant="outline-danger" class="ml-2" @click="deleteDeliveries" :disabled="!selectedRows.length"
            ><feather-icon icon="TrashIcon"
          /></b-button>
        </span>
        <span v-b-tooltip="!selectedRows.length ? $t('msg-alert-disabled-panel') : ''">
          <b-button
            variant="warning"
            class="ml-2"
            @click="openModal('modalDeliveryLabels')"
            :disabled="!selectedRows.length"
            ><feather-icon icon="PrinterIcon"
          /></b-button>
        </span>
        <span v-b-tooltip="!selectedRows.length ? $t('msg-alert-disabled-panel') : ''">
          <b-button variant="warning" class="ml-2" @click="openModal('modalChangeCarrier')" :disabled="!selectedRows.length">
            <feather-icon icon="ShuffleIcon"/>
          </b-button>
        </span>
        <span class="ml-2">
          <strong>{{selectedRows.length}} envíos seleccionados</strong>
        </span>
      </div>

      <b-skeleton v-show="loading.first" type="input" class="mt-2 mb-2 spacing-label-field" height="40px" />
      <table-render
        id="listDeriveries"
        v-if="!loading.first"
        showCheckboxes="true"
        :selectedRows.sync="selectedRows"
        :loading="loading.printed"
        class="mt-2"
        :schema="schema"
        :rows="rows"
      >
        <template #status="scope">
          <span
            class="mr-1"
            :class="`table_dot--state ${$options.filters.getStatusClasses()[scope.rowdata.delivery_status_id]}`"
          ></span>
          {{ scope.rowdata.status_name }}
        </template>
        <template #imported_id="scope">
          <b-button
            class="p-0"
            variant="link"
            @click="openDeliveryDetails(scope.rowdata.id)"
            >{{scope.rowdata.imported_id}}</b-button
          >
        </template>
        <template #client_name="scope">
          <span v-if="!scope.rowdata.customer_name">Sin info.</span>
          <a
            v-else
            v-b-tooltip.click.html
            :title="(scope.rowdata.customer_contact)"
            class="lnk underline-text-deliveries"
            >{{scope.rowdata.customer_name}}</a
          >
        </template>
        <template #sku="scope">
          {{scope.rowdata.sku || "Sin info."}}
        </template>
      </table-render>
      <pagination v-if="!loading.first" :pagination="pagination" :noDigits="true" />
      <div v-if="loading.first" class="table-render-skeleton">
        <b-skeleton-table :rows="10" :columns="schema.length || 10" :table-props="{ }" />
      </div>
    </b-card>
    <modal-multi-search @filter="fullFilters" ref="modalMultiSearch"></modal-multi-search>
    <modal-avanced-filter
      @filter="filterForm"
      @setLink="setDownloadLink"
      @showAlert="showAlert"
      :date.sync="formFilter.date_range"
      ref="modalAvancedFilterDeliveries"
      isPrintTab="true"
    ></modal-avanced-filter>
    <modal-delivery-labels
      :deliveriesIds="selectedRows"
      @sendLabelResponse="collectLabelsPrintData"
      :firstPrint="true"
      :dataMessage="dataMessage"
    ></modal-delivery-labels>
    <modal-delivery-labels-print :orderedLabels="orderedLabels"></modal-delivery-labels-print>
    <modals
      id="modalChangeCarrier"
      :dataModal="modalCarrier"
      @onSend="fetchChangeCarrier"
      @show="onShowChangeCarrier"
    ></modals>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import deliveriesListService from '../../list-deliveries/deliveriesList.service'
import ModalAvancedFilter from '../ModalAvancedFilter.vue'
import ModalMultiSearch from '../generate-labels/ModalMultiSearch.vue'
import ModalDeliveryLabelsPrint from '../printed/ModalDeliveryLabelsPrint.vue'
import ModalDeliveryLabels from '../printed/ModalDeliveryLabels.vue'
import DeliveryService from '../delivery.service'
import Modals from '../../list-deliveries/detail-delivery/Modals.vue'
import * as moment from 'moment'
import { environment } from '@/environments/environment'

export default {
  components: { ModalAvancedFilter, ModalMultiSearch, ModalDeliveryLabels, ModalDeliveryLabelsPrint, Modals },
  data () {
    return {
      buttons: [],
      filters: {},
      formFilter: {},
      dialogOpenExtendedFilter: false,
      fields: [],
      fieldsType: [],
      buttonSend: { title: 'Buscar', icon: 'SearchIcon', color: 'warning' },
      schema: [],
      rows: [],
      // currentFilter: {},
      advancedFilters: {},
      initialDateRange: {
        start: moment().add(-35, 'days').toDate(),
        end: moment().add(7, 'days').toDate()
      },
      initialStatusOption: { text: 'Todos', id: '5' },
      pagination: {
        page: 1,
        total: 40,
        limit: 20
      },
      keyFormRender: 0,
      clearModalFilters: 0,
      user: null,
      loading: {
        first: true,
        printed: false,
        excel: false
      },
      selectedRows: [],
      serviceType: null,
      deliveryLabelUrl: null,
      // showingActions: false,
      callWithPagination: false,
      deliveriesListService: new deliveriesListService(this),
      deliveryService: new DeliveryService(this),
      listCount: {
        withSummaries: 0,
        noSummaries: 0
      },
      selectedCheck: 1,
      orderedLabels: [],
      modalCarrier: {
        fields: [
          {fieldType: 'FieldSelect', name: 'carrier', label: 'Nuevo courier', containerClass: 'col-12 container-info', useLabel: true, clearable: true, useSkeleton: true, change: this.changeOrganization, validation: 'required'}
        ],
        nameButton: 'Reinyectar',
        isEnableButton: true,
        title: 'Cambiar de courier',
        form: {},
        loading: false,
        key: 0
      },
      dataMessage: {}

    }
  },
  computed: {
    ...mapGetters({
      mySession: 'getSession',
      deliveriesPlat: 'getDeliveriesPlat',
      generalLoading: 'getLoading'
    })
  },
  mounted () {
    this.getAllInitialData()
  },
  watch: {
    mySession () {
      if (this.mySession.id) this.setInitialData()
    },
    deliveriesPlat () {
      this.loading.first = false
      this.setDataRows(this.deliveriesPlat.rows)
      if (this.deliveriesPlat.total >= 50) this.callWithPagination = true
    },
    generalLoading: {
      handler () {
        this.loading.printed = !!this.generalLoading.getDeliveriesPlat
      }
    },
    'pagination.page' () {
      this.getDeliveries()
    }
  },
  methods: {
    getAllInitialData () {
      if (this.mySession.id) this.setInitialData()
    },
    getDeliveries () {
      const payloadFilters = this.setPayload('filterList')
      this.selectedRows = []
      this.$store.dispatch('fetchService', { name: 'getDeliveriesPlat', queryParams: payloadFilters,
        onSuccess: () => {
          if (!!this.$refs.modalMultiSearch) this.$refs.modalMultiSearch.resetForm()
        },
        onError: () => this.loading.printed = false})
    },
    getMessage() {
      let carrier_count = 0
      let allCarriers = ''
      let carriers = ''
      let band_alert = false
      let total_manifest = 0
      let carrier_name_alert = ''
      let band_count_carrier_alert = false
      let services_alert = ''
      const object_manifest = {}
      const object_carrier = {}
      this.deliveriesPlat.rows.forEach(item => {
        if (this.selectedRows.includes(item.id)) {
          let service_string = ''
          let services = ''
          if (this.deliveriesPlat.service_groups[item.carrier_name]) this.deliveriesPlat.service_groups[item.carrier_name].forEach(service_separate => {
            services = services !== '' ? `${services} y ${service_separate.replace(/_/g, '/')}` : service_separate.replace(/_/g, '/')
            if (service_separate.indexOf(item.extra_fields.service.value) !== -1) {
              service_string = `_${service_separate}`
            }
          })
          if (service_string !== '') {
            band_alert = true
          }
          const key_string = `${item.company_id}_${item.warehouse_id}_${item.carrier_id}${service_string}`
          if (!Object.hasOwnProperty.call(object_manifest, key_string)) {
            total_manifest++
            if (service_string !== '') {
              carrier_name_alert = item.carrier_name
              allCarriers = allCarriers !== '' ? `${allCarriers}, ${item.carrier_name}` : item.carrier_name
              const carriersArray = allCarriers.split(', ')
              const carriersArrayFiltered = [...new Set(carriersArray)]
              carriers = carriersArrayFiltered.join(', ')
              services_alert = services
            }
          }
          if (!Object.hasOwnProperty.call(object_carrier, item.carrier_name) && service_string !== '') {
            object_carrier[item.carrier_name] = true
            carrier_count++
          }
        }
      })
      if (band_alert && carrier_count > 1) {
        band_count_carrier_alert = true
      }
      this.dataMessage = { band_count_carrier_alert, carrier_name_alert, band_alert, carriers, total_manifest, services_alert }
    },
    setInitialData () {
      this.schema = [
        {label: 'Comercio', key: 'company_name', sortable: true},
        {label: 'Estado', key: 'status', sortable: true, useSlot: true},
        {label: 'N° de envío', key: 'imported_id', sortable: true, useSlot: true},
        {label: 'Orden de flete', key: 'tracking_number', sortable: true},
        {label: 'Cliente', key: 'client_name', sortable: true, useSlot: true},
        {label: 'Comuna', key: 'place_name', sortable: true},
        {label: 'Courier', key: 'carrier_name', sortable: true},
        {label: 'SKU', key: 'sku', sortable: true, useSlot: true},
        {label: 'Fecha creación', key: 'created_at_formatted', sortable: true, wrap: true}
      ]
      this.fields = [
        {
          fieldType: 'FieldDatepicker',
          name: 'date_range',
          label: 'Fecha de creación',
          range: true,
          containerClass: 'col-sm-12 col-md-6 col-lg-3'
        },
        {
          fieldType: 'FieldInput',
          label: this.$t('N. de envío'),
          name: 'number',
          placeholder: this.$t('Buscar envío'),
          containerClass: 'col-sm-12 col-md-6 col-lg-3'
        }

      ]
      this.formFilter = {
        date_range: this.initialDateRange,
        status: this.initialStatusOption,
        sameday: false
      }
      this.user = this.$session.get('cas_user')
      this.customTableByRole()
      this.filterList(this.formFilter)
    },
    setDataRows (data) {
      this.rows = data.map(el => ({...el, id: el.id}))
    },
    setDownloadLink (url, name) {
      this.downloadLink = url
      this.downloadName = name
    },
    customTableByRole () {
      if (this.user.role === 'ecommerce') {
        this.schema.shift()
      }
    },
    openModal(modal) {
      if (modal === 'modalDeliveryLabels') this.getMessage()
      this.$bvModal.show(modal)
    },
    filterForm (form, isExportation) {
      this.filterList({...this.advancedFilters, ...form}, isExportation)
    },
    fullFilters(data) {
      this.advancedFilters = data
      this.filterList({...this.formFilter, ...data})
    },
    filterList (data, isExportation = false) {
      this.callWithPagination = false
      this.filters = {}
      this.filters['flow_action'] = 'CREATED_REPEATED-CREATED'
      Object.keys(data).map(key => {
        if (key !== 'date_range') {
          if (key === 'company') {
            if (this.$refs.modalAvancedFilterDeliveries.setIsMarketplace() && data[key]?.id) {
              this.filters.marketplace = Number(data[key]?.id)
            } else if (data[key]?.id) {
              this.filters.company = Number(data[key]?.id)
            }
          }
          else if (['carrier', 'pudo', 'country', 'place', 'place_id', 'type', 'multi_package'].includes(key)) this.filters[key] = Number(data[key]?.id)
          else if (key === 'service') this.filters[key] = data[key].code
          else if (key === 'customer') this.filters[key] = data[key]
          else if (key === 'phone') this.filters[key] = data[key]
          else if (key === 'sku') this.filters[key] = data[key]
          else if (key === 'place_level_1') this.filters[key] = data[key].name
          else if (key === 'contract_type') this.filters[key] = data[key].map(action => action.id).join(',')
          else if (key === 'sellers') this.filters[key] = data[key].map(data => Number(data.id))
          else if (key === 'sameday' && data[key]) this.filters[key] = 1
          else if (key === 'number') this.filters[key] = data[key]
          else if (key === 'email') this.filters[key] = data[key]
          else if (key === 'later_status' && data[key]) this.filters[key] = 1
          if (data.service?.code && data.carrier?.dispatch_in) {
            this.filters.dispatch_in = data.carrier.dispatch_in
            this.filters.service = (data.carrier.dispatch_in === 'akihabara') ? data.service.code : data.service.id
          }
        } else {
          this.filters.origin_created_at_from = this.$options.filters.moment(data.date_range.start, 'YYYY-MM-DD')
          this.filters.origin_created_at_to = this.$options.filters.moment(data.date_range.end, 'YYYY-MM-DD')
        }
      })
      if (!isExportation) {
        this.pagination.page !== 1 ? this.pagination.page = 1 : this.getDeliveries()
      } else {
        //Se llama desde aqui para poder tomar el dato del filtro simple tambien
        this.callExportFn()
      }
    },
    collectLabelsPrintData (data) {
      this.orderedLabels = data
      this.$bvModal.hide('modalDeliveryLabels')
      this.filterForm()
      this.showLabelsPrints()
    },
    showLabelsPrints () {
      this.$bvModal.show('modalDeliveryLabelsPrint')
    },
    callExportFn() {
      this.loading.excel = true
      this.cleanLinks()
      this.$refs.modalAvancedFilterDeliveries.exportData(this.filters)
    },
    showAlert (msg, des, type) {
      this.$bvToast.toast(msg, {
        title: des,
        variant: type,
        solid: true
      })
      if (type !== 'success') this.ErrorDownloadLink = msg
      this.loading.excel = false
    },
    cleanLinks () {
      this.ErrorDownloadLink = null
      this.downloadLink = null
      this.downloadName = null
    },
    cleanFilter () {
      this.formFilter = {
        date_range: this.initialDateRange,
        status: this.initialStatusOption
      }
      this.callWithPagination = false
      this.clearModalFilters++
      this.pagination.page = 1
      this.filterList(this.formFilter)
    },
    openDeliveryDetails (deliveryId) {
      this.m_openWinBlank(`/deliveries/${deliveryId}`)
    },
    setPayload (typePayload) {
      const payload =  {
        ...this.filters
      }
      if (this.callWithPagination) payload.calls = this.pagination.page
      if (this.user.role !== 'admin') payload.user = {}
      const roles = {
        admin: 1,
        ecommerce: 2,
        pudo: 3,
        marketplace: 4
      }

      if (typePayload === 'export') {
        if (['marketplace'].includes(this.user.role)) {
          payload.user.organization_id = this.user.organization.id
          payload.user.role_id = roles[this.user.role]
        }
        if (['ecommerce'].includes(this.user.role)) {
          payload.user.shipper_id = this.user.shipper.id
          payload.user.role_id = roles[this.user.role]
        }
      } else if (typePayload === 'filterList') {
        if (['marketplace'].includes(this.user.role)) payload.organization_id = this.user.organization.id
        if (['ecommerce'].includes(this.user.role)) payload.shipper_id = this.user.shipper.id
      }

      return payload
    },
    fetchChangeCarrier(form) {
      const data = {
        name: 'putChangeCarrier',
        queryParams: {
          deliveries: this.selectedRows,
          carrier_code: form.carrier?.code
        },
        onSuccess: () => { this.$bvModal.hide('modalChangeCarrier'); this.filterForm() },
        onError: () => this.modalCarrier.loading = false
      }
      this.$store.dispatch('fetchService', data)
    },
    onShowChangeCarrier() {
      const { fields } = this.modalCarrier
      const data = {
        name: 'getReinjectionCarriersById',
        queryParams: {
          deliveries: this.selectedRows
        },
        onSuccess: (resp) => { 
          fields.find((field) => field.name === 'carrier').options = resp.data.map(el => ({...el, text: el.name}))
          fields.find((field) => field.name === 'carrier').useSkeleton = false
        },
        onError: () => {
          this.modalCarrier.loading = false
          fields.find((field) => field.name === 'carrier').useSkeleton = false
        }
      }
      this.$store.dispatch('fetchService', data)
    },
    deleteDeliveries () {
      this.$confirm(
        'Antes de eliminar los envíos se verificará que no esten en movimiento, de ser así los envíos en movimiento no se eliminaran.<br><br>La operación puede tomar varios minutos.',
        () => {
          this.loading.deliveries = true
          this.$loadingAlert({text: 'Eliminando envíos, por favor espere...', color: 'primary'})
          const queryParams = {
            force_tracking: true,
            deliveries: this.selectedRows
          }
          this.$store.dispatch('fetchService', {
            name: 'deleteDeliveries',
            queryParams,
            onSuccess: () => {
              this.m_closeAlertLoading()
              this.$bvToast.toast(`Envios: ${this.selectedRows} eliminados correctamente`, {
                title: 'Éxito',
                variant: 'success',
                solid: true
              })
              this.filterList(this.formFilter)
            },
            onError: () => {
              this.loading.deliveries = false
              this.m_closeAlertLoading()
            }
          })
        },
        this.$t('msg-pregunta-eliminar-envios')
      )
    }
  }
}
</script>
<style lang="scss">
.search-advanced {
    text-decoration: underline !important;
}
.custom-switch-success {
  margin-top: 0.5rem;
  > label {
    font-size: 14px !important;
  }
}
.underline-text-deliveries {
  text-decoration: underline;
}
.dropdown-buttons-print {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
</style>
