<template>
  <div>
    <b-modal
    v-bind="$attrs"
    v-on="$listeners"
    :title="$t(dataModal.title)"
    size="md"
    ok-variant="warning"
    ok-only
    no-close-on-esc
    no-close-on-backdrop
    centered
    no-footer
    >
      <form-render
        :form.sync="dataModal.form"
        :fields="dataModal.fields"
        containerButtonsClass="col-sm-12"
        @send="onSend"
        ref="formModals"
        :key="dataModal.keyformModals"
      >
      </form-render>
      <template #modal-footer>
        <b-button variant="warning" @click.prevent="ok()" :disabled="dataModal.loading && dataModal.isEnableButton">
        <b-spinner v-show="dataModal.loading" small></b-spinner>
        {{$t(dataModal.nameButton)}}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import FormRender from '@/views/components/custom/form-render/FormRender.vue'
export default {
  components: { FormRender },
  props: {
    dataModal: { type: Object, default: () => ({}) }
  },
  methods: {
    async ok(e) {
      await this.$refs.formModals.checkForm()
    },
    onSend(form) {
      this.$emit('onSend', form)
    }
  }
}
</script>