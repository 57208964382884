<template>
  <div class="company-dialog">
    <b-modal
      id="modalDeliveryLabels"
      centered
      modal-class="modal-warning dialog-600 custom-dialog"
      hide-footer
    >
      <div class="mt-0 text-center">
        <h2 class="mb-2"><strong>{{firstPrint ? 'Tipo de impresión' : 'Reimprimir etiqueta'}}</strong></h2>
        <span v-if="!dataMessage"> <strong>{{deliveriesIds.length}} envío(s) seleccionados</strong> </span> 
        <h3 v-if="!dataMessage">Seleccione tipo de impresión</h3>
        <div class="message-print p-1 m-2 text-justify" v-if="dataMessage && dataMessage.band_count_carrier_alert"><span class="mt-2 mb-2">Los couriers {{dataMessage.carriers}} de los envíos seleccionados tienen para distintos servicios operaciones separadas, por lo que se le {{ dataMessage.total_manifest === 1 ? 'generará' : 'generarán'}} <strong>{{dataMessage.total_manifest}}</strong> {{dataMessage.total_manifest === 1 ? 'manifiesto' : 'manifiestos'}}. Recuerde separar su carga correctamente para evitar confusiones.</span></div>
        <div class="message-print p-1 m-2 text-justify" v-if="dataMessage && !dataMessage.band_count_carrier_alert && dataMessage.carrier_name_alert !== ''"><span class="mt-2 mb-2">El courier <strong>{{dataMessage.carriers}}</strong> tiene operación distinta para los servicios <strong>{{dataMessage.services_alert}}</strong> por lo que con los envios seleccionados se les generarán manifiestos distintos. Recuerde separar su carga correctamente para evitar confusiones.</span></div>
        <div class="message-print p-1 m-2 text-justify" v-if="dataMessage && !dataMessage.band_alert && !dataMessage.band_count_carrier_alert && dataMessage.carrier_name_alert === ''"><span class="mt-2 mb-2">Total de manifiestos a generar: <strong>{{dataMessage.total_manifest}}</strong>.</span></div>
        <!-- {{deliveriesIds}}, {{list}} {{carrierCodes}} -->
        <div class="container my-2">
          <div class="row d-flex justify-content-around">
            <b-button class="col-md-3" variant="warning" @click="selectPrintType(1)"> <b-spinner v-if="loading.type_1" type="grow"></b-spinner> <span v-else>{{$t('Zebra')}}</span> </b-button>
            <b-button class="col-md-3" variant="warning" @click="selectPrintType(2)"> <b-spinner v-if="loading.type_2" type="grow"></b-spinner> <span v-else>{{$t('Hoja Carta')}}</span> </b-button>
            <b-button class="col-md-3" variant="warning" @click="selectPrintType(3)"> <b-spinner v-if="loading.type_3" type="grow"></b-spinner> <span v-else>{{$t('ZPL')}}</span> </b-button>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import DeliveryService from '../delivery.service'
export default {
  props: {
    deliveriesIds: {required: false, type: Array},
    serviceType: {required: false, type: String},
    firstPrint: {required: false, type: Boolean},
    dataMessage: {required: false, type: Object},
    refreshTable: {required: false, type: Boolean}
  },
  data() {
    return {
      deliveryService: new DeliveryService(this),
      loading: {
        type_1: false,
        type_2: false,
        type_3: false
      }
    }
  },
  methods: {
    selectPrintType (type) {
      let data = {
        print_type: type
      }
      if (this.firstPrint) data = { type }
      this.firstPrint ? data.deliveries = this.deliveriesIds : data.delivery_ids = this.deliveriesIds
      switch (type) {
      case 1: 
        this.loading.type_1 = true
        break
      case 2: 
        this.loading.type_2 = true
        break
      case 3: 
        this.loading.type_3 = true
        break
      }
      if (this.serviceType === 'only-print' || this.firstPrint) this.printLabelsEstambul(data)
      else this.assignAndPrint(data)
    },
    printLabelsEstambul (qry) {
      let name = 'getDeliveryLabels'
      if (this.firstPrint) name = 'postDeliveriesPrint'
      this.deliveryService.callService(name, qry, qry)
        .then(resp => {
          this.$emit('sendLabelResponse', resp.data)
        })
        .catch(error => {
          this.$alert(this.$t('msg-problem-delivery-label', {code: error}))
        })
        .finally(_ => {
          this.loading.type_1 = false
          this.loading.type_2 = false
          this.loading.type_3 = false
        })
    },
    assignAndPrint (qry) {
      this.loading.assignSummary = true
      this.deliveryService.callService('assignToSummary', qry, null)
        .then(resp => {
          this.$emit('sendLabelResponse', resp.data, this.refreshTable)
        })
        .catch(error => {
          this.$alert(this.$t('msg-problem-delivery-label', {code: error}))
        })
        .finally(_ => {
          this.loading.type_1 = false
          this.loading.type_2 = false
          this.loading.type_3 = false
        })
    }

  }
}
</script>

<style>
.message-print {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc;
  border: 1px solid #faebcc;
  border-radius: 4px;
}
</style>