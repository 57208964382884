<template>
	<div class="company-dialog">
		<b-modal id="modalExtendedFilter" centered modal-class="modal-warning dialog-1000 custom-dialog"
    @ok="ok" ok-variant="warning" :ok-title="$t('Buscar')" ok-only
    :title="$t('Búsqueda avanzada')">
			<div class="mt-0">
				<form-render :key="keyFormRender" :fields="fields" :form.sync="formFilters" @send="onAccept"
          ref="formRenderExtendedFilter"
          containerButtonsClass="col-sm-12 col-lg-4 container-button">
				</form-render>
			</div>
		</b-modal>
	</div>
</template>
<script>

import { mapGetters } from 'vuex'
import DeliveryService from '../deliveries/create/delivery.service'

export default {
  props: ['form', 'clearFilters'],
  data () {
    return {
      buttonSend: {text: 'Filtrar', icon: 'SearchIcon', color: 'warning'},
      fields: [],
      fieldSkipLine: { name: 'line', useSkeleton: true, skipLine: true, containerClass: 'col-12' },
      user: null,
      keyFormRender: 0,
      formFilters: {},
      deliveryService: new DeliveryService(this),
      ROLES : {
        marketplace: 'marketplace',
        seller: 'seller',
        admin: 'admin'
      },
      placesParamsServive: {
        countryCode: null,
        level: null
      },
      statusOptions: [
        {
          text: 'Listo para despacho - Impreso',
          id: 26
        },
        {
          text: 'Manifiesto confirmado',
          id: 75
        },
        {
          text: 'Todos',
          id: '26,75'
        }
      ],
      levelsBycountry: {
        CL: {
          levels: 3,
          labelText: 'Comuna'
        },
        MX: {
          levels: 4,
          labelText: 'Colonia'
        },
        CO: {
          levels: 2,
          labelText: 'Departamento'
        },
        PE: {
          levels: 3,
          labelText: 'Distrito'
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      mySession: 'getSession'
    })
  },
  watch: {
    clearFilters () {
      this.formFilters = {}
    },
    form () {
      this.formFilters = {
        ...this.formFilters,
        ...this.form
      }
      this.setInitialData()
      this.keyFormRender++
    },
    mySession () {
      if (this.mySession.id) this.setInitialData()
    },
    'formFilters.date' () {
      this.$emit('updateFilterDate', this.formFilters.date)
    },
    'formFilters.carrier' () {
      if (!this.formFilters.carrier) this.formFilters.carrier_service = null
      else this.getCarrierServices()
      // if (this.formFilters.carrier && !this.formFilters.carrier_service) this.getCarrierServices()
    }
  },
  mounted () {
    this.getAllInitialData()
  },
  methods: {
    getAllInitialData () {
      if (this.mySession.id) this.setInitialData()
    },
    setInitialData () {
      this.fields = [
        {
          fieldType: 'FieldDatepicker', 
          name: 'date', 
          label: 'Fecha de creación', 
          range: true, 
          containerClass: 'col-md-4 container-info col-sm-12'
        },
        {
          fieldType: 'FieldSelect', 
          clearable: true, 
          label: 'Empresa', 
          name: 'company_id', 
          containerClass: 'col-md-4 container-info col-sm-12', 
          searchOnType: { fx: this.searchShippers, nChars: 3, debounce: 600 }
        },
        {
          fieldType: 'FieldInput', 
          name: 'summary_id', 
          type: 'number', 
          label: 'N° Manifiesto', 
          clearable: true, 
          containerClass: 'col-md-4 container-info col-sm-12'
        },
        this.fieldSkipLine,
        // Filtros de place
        {
          fieldType: 'FieldSelect', 
          clearable: true, 
          label: 'País', 
          name: 'country_id', 
          change: this.changeCountryField,
          containerClass: 'col-md-4 container-info col-sm-12', 
          addFlags: true
        },
        {
          fieldType: 'FieldSelect', 
          clearable: true, 
          label: 'Comuna', 
          name: 'place',
          searchOnType: { 
            fx: this.setPlaceFilter, 
            nChars: 3, 
            debounce: 600 
          },
          containerClass: 'col-md-4 container-info col-sm-12'
        },
        // this.fieldSkipLine,
        {
          fieldType: 'FieldSelect', 
          clearable: false, 
          label: 'Estado de tracking', 
          name: 'status', 
          containerClass: 'col-md-4 container-info col-sm-12', 
          options: this.statusOptions
        },
        {
          fieldType: 'FieldSelect', 
          clearable: true, 
          label: 'Courier', 
          name: 'carrier', 
          containerClass: 'col-md-4 container-info col-sm-12', 
          searchOnType: { 
            fx: this.getCarriers, 
            nChars: 3, 
            debounce: 300, 
            allowSearchAsValue: true, 
            persistSearch: true 
          }
        },
        {
          fieldType: 'FieldSelect', 
          clearable: true, 
          label: 'Servicio', 
          name: 'carrier_service', 
          containerClass: 'col-md-4 container-info col-sm-12', 
          dependency: 'carrier'
        },
        {
          fieldType: 'FieldSelect', 
          clearable: true, 
          label: 'Tipo de distribución', 
          name: 'distribution_type', 
          containerClass: 'col-md-4 container-info col-sm-12', 
          multiple: true, 
          options: [
            { id: 1, contract_type: 'pasarela', text: 'Pasarela' },
            { id: 2, contract_type: 'distribucion', text: 'Distribución' },
            { id: 3, contract_type: 'pasarela-pro', text: 'Pasarela-pro' }
          ]
        }
      ]
      this.user = this.$session.get('cas_user')
      this.customFormByRole()
    },
    customFormByRole () {
      // Para usuario Shipper y/o mkp se deben sacar ciertos fields del form
      if (this.user.role === this.ROLES.seller) this.fields = this.fields.filter(field => !['company_id', 'country_id', 'distribution_type'].includes(field.name)) 
      if (this.user.role === this.ROLES.marketplace) this.fields = this.fields.filter(field => !['country_id', 'distribution_type'].includes(field.name)) 
      if (this.user.role === this.ROLES.admin) {
        this.searchCountry()
        // Se añade dependencia entre el campo de 'Country' y 'Place' Solo para ADMINS
        this.fields[this.selectionIndex('place')].dependency = 'country_id'
      } else {
        // condiciones para user que no sean admins
        this.placesParamsServive.countryCode = this.user.country.code
        this.fields[this.selectionIndex('place')].label = this.levelsBycountry[this.user.country.code].labelText
      }
    },
    changeCountryField () {
      if (this.formFilters.country_id) {
        this.placesParamsServive.countryCode = this.formFilters.country_id.code
        this.fields[this.selectionIndex('place')].label = this.levelsBycountry[this.formFilters.country_id.code].labelText
      } else {
        this.fields[this.selectionIndex('place')].label = this.levelsBycountry['CL'].labelText
      }
      this.formFilters.place = null
      this.fields[this.selectionIndex('place')].options = []
      this.$refs.formRenderExtendedFilter.updateUIForm()
    },
    setPlaceFilter (search) {
      const queryParams = {
        level: this.levelsBycountry[this.placesParamsServive.countryCode].levels,
        name: search
      }
      const params = {
        country_code:this.placesParamsServive.countryCode
      }
      return this.deliveryService.callService('getLevels', queryParams, params)
        .then(resp => {
          return this.parseDubaiPlaces(resp.data.level1)
        })
    },
    parseDubaiPlaces(places) {
      const parsedPlaces = this.parseDubaiPlacesRecursive(places)
      parsedPlaces.forEach(place => {
        place.text = place.text.join(', ')
      })
      return parsedPlaces
    },

    parseDubaiPlacesRecursive(places, lastParent = null) {
      let findPlaces = []
      places.map(place => {
        const levelKey = Object.keys(place).find((key) => key.includes('level'))
        if (levelKey) {
          findPlaces = findPlaces.concat(this.parseDubaiPlacesRecursive(place[levelKey], place))
        } else {
          findPlaces.push({
            id: place.id,
            name: place.name,
            text: [place.name],
            platform_id: place.platform_id
          })
        }
      })

      if (lastParent) {
        findPlaces.forEach(place => {
          place.text.push(lastParent.name)
          place.parent = lastParent
        })
      }
      return findPlaces
    },
    getCarriers (value) {
      return this.deliveryService.callService('getCarriersNY', {name: value})
        .then(response => {
          return response.data.map(el => ({...el, text: `${el.id} - ${el.name} - ${el.code}`}))
        }).catch(err => {
          console.error(err)
        })
    },
    getCarrierServices () {
      if (this.formFilters.carrier) {
        const qry = {
          country_id: this.user.country.id,
          carrier_id: this.formFilters.carrier.id
        }
        this.deliveryService.callService('getCarrierServicesNY', null, qry)
          .then(response => {
            const index = this.selectionIndex('carrier_service')
            const selectOptions = response.data.map(service => ({ ...service, text:service.name }))
            this.fields[index].options = selectOptions
            this.keyFormRender++
          }).catch(err => {
            console.error(err)
          })
      }
    },
    searchShippers (value) {
      let service = 'getShippers'
      const params = {}
      if (this.user.role === this.ROLES.marketplace) {
        service = 'getShippersByOrganizationSimplifiedQueryParams'
        params.organization_id = this.user.organization.id
      }
      return this.deliveryService.callService(service, {name: value}, params)
        .then(response => {
          return response.data.map(el => ({...el, text: `${el.id} - ${el.name1 ? el.name1 : el.name}`}))
        })
        .catch(error => console.error(error))
    },
    searchCountry () {
      return this.deliveryService.callService('getCountries', null, null)
        .then(response => {
          let options = response.data.map(el => ({...el, text: `${el.name1 ? el.name1 : el.name}`}))
          const index = this.fields.findIndex(el => el.name === 'country_id')
          // Sacamos del array Argentina y Ecuador
          options = options.filter(country => !['AR', 'EC'].includes(country.code))
          this.fields[index].options = options
          this.keyFormRender++
        })
        .catch(error => console.error(error))
    },
    onAccept () {
      this.$emit('result', this.formFilters)
    },
    selectionIndex(name) {
      return this.fields.findIndex(el => el.name === name)
    },
    ok (e) {
      e.preventDefault()
      this.$refs.formRenderExtendedFilter.checkForm()
    }
  }
}
</script>
<style>

</style>
