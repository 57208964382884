<template>
  <div>
    <generate-labels :typeName="'rejected'"/>
  </div>
</template>

<script>
import GenerateLabels from '../generate-labels/GenerateLabels.vue'

export default {
  props: [],
  components: { GenerateLabels },
  data () {
    return {}
  }
}
</script>

<style></style>
