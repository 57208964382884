<template>
  <div class="ticket-list">
    <b-card>
      <form-render ref="printedForm" :key="keyFormRender" class="mb-2" :form.sync="formFilter" :fields="fields" @send="filterForm" :buttonSend="buttonSend" containerButtonsClass="col-sm-12 col-md-8 col-lg-6 container-button mt-2">
          <template #buttons>
            <!-- <b-button variant="outline-light" v-b-tooltip.hover :title="$t('Limpiar filtros')" class="ml-2" @click="cleanFilter"><feather-icon icon="RefreshCwIcon"/></b-button>  
            <b-button class="search-advanced" variant="link" @click="openAdvancedFiltersModal">{{ $t('advanced-search') }}</b-button> -->
            <b-dropdown class="mx-1" v-b-tooltip.hover :title="$t('Más opciones')" v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="light"> 
              <template #button-content><feather-icon icon="SettingsIcon"/></template>
              <b-dropdown-item @click="cleanFilter">{{$t('Limpiar filtros')}}</b-dropdown-item>
              <b-dropdown-item @click="openAdvancedFiltersModal">{{$t('Búsqueda avanzada')}}</b-dropdown-item>
            </b-dropdown>
            <b-form-checkbox class="custom-switch-success push-right" :checked="formFilter.sameday" switch v-model="formFilter.sameday" @change="filterForm(formFilter)"> {{$t('Servicio Sameday')}}</b-form-checkbox>
          </template>
      </form-render>
      
      <form-render v-show="!loading.first" class="px-0 mt-2 mb-2" :fields="fieldsType" :form.sync="selectedRadioOption"></form-render>
      <div v-show="!loading.first" class="col-md-12">
        <!-- Reimprimir (Con manifiesto) -->
        <span v-b-tooltip="disabledPanel ? $t('msg-alert-disabled-panel') : ''">
        <b-button v-if="selectedCheck === 1" :disabled="disabledPanel" @click="showLabelsPrintOptions('only-print')" variant="outline-light" class=""><feather-icon icon="PrinterIcon"/> Reimprimir</b-button> 

        </span>
        <!-- Dropdown opstions (Para sin manifiesto)-->
        <b-dropdown v-if="selectedCheck === 2" class="" variant="outline-light">
          <template #button-content>
            <feather-icon icon="ListIcon"/> Más opciones
          </template>
          <!-- Reimprimir (Sin manifiesto) -->
          <b-dropdown-item v-b-tooltip="disabledPanel ? $t('msg-alert-disabled-panel-reprint') : ''" :disabled="disabledPanel" @click="showLabelsPrintOptions('only-print')"> <feather-icon icon="TagIcon"/> {{ $t('Reimprimir etiqueta') }} </b-dropdown-item>
          <b-dropdown-item v-b-tooltip="disabledPanel ? $t('msg-alert-disabled-panel-assign') : ''" :disabled="disabledPanel" @click="showLabelsPrintOptions('only-assign')"> <feather-icon icon="FileIcon"/> {{ $t('Asignar a manifiesto') }} </b-dropdown-item>
          <b-dropdown-item v-b-tooltip="disabledPanel ? $t('msg-alert-disabled-panel') : ''" :disabled="disabledPanel" @click="showLabelsPrintOptions('assign-print')"> <feather-icon icon="PrinterIcon"/> {{ $t('Reimprimir y asignar a manifiesto') }} </b-dropdown-item>
        </b-dropdown> 
        <!-- <b-button variant="outline-danger" class="ml-2"><feather-icon icon="TrashIcon"/> Eliminar</b-button> -->
        <span class="ml-2"> <strong>{{selectedRows.length}} envíos seleccionados</strong> </span>  

        <b-button @click="downloadExcel" :disabled="loading.excel" class="push-right" variant="outline-success">
          <feather-icon v-if="!loading.excel" icon="DownloadIcon"/> <i v-else :class="['fa', 'fa-spinner', 'fa-spin']"/>  {{$t('Exportar a excel')}} 
        </b-button>
      </div>
      
      <b-skeleton v-show="loading.first" type="input" class="mt-2 mb-2 spacing-label-field" height="40px"/>
      <table-render id="listDeriveries" v-if="!loading.first" showCheckboxes="true" :selectedRows.sync="selectedRows" :loading="loading.printed" class="mt-2" :schema="schema" :rows="rows">
        <template #status="scope">
          <span class="mr-1" :class="`table_dot--state ${$options.filters.getStatusClasses()[scope.rowdata.status.id]}`"></span> {{ scope.rowdata.status.name }}
        </template>
        <template #imported_id="scope">
          <a class="search-advanced" @click="openDeliveryDetails(scope.rowdata.id, scope.rowdata.company.id)" target="_blank">{{scope.rowdata.imported_id}}</a>
        </template>
        <template #client_name="scope">
          <b-button v-b-tooltip.hover.html="$generateTooltipPerson(scope.rowdata.client)" variant="link " >{{scope.rowdata.client.name}}</b-button>
        </template>
      </table-render>
      <pagination v-if="!loading.first" :pagination="pagination" :noDigits="true" :showSize="true"/>
      <div v-if="loading.first" class="table-render-skeleton">
        <b-skeleton-table
          :rows="10"
          :columns="schema.length || 10"
          :table-props="{ }"/>
      </div>
      <modal-extended-printed-filter :form.sync="formFilter" :clearFilters="clearModalFilters" @updateFilterDate="updateFilterDate" @result="collectFullFilter"></modal-extended-printed-filter>
      <modal-delivery-labels :deliveriesIds="selectedRows" :serviceType="serviceType" @sendLabelResponse="collectLabelsPrintData"></modal-delivery-labels>
      <modal-delivery-labels-print :orderedLabels="orderedLabels" ></modal-delivery-labels-print>
      <modal-assign-summary :deliveriesIds="selectedRows" :processType="serviceType"></modal-assign-summary>

    </b-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DeliveryService from '../delivery.service'
import ModalExtendedPrintedFilter from '../../ModalExtendedPrintedFilter.vue'
import ModalDeliveryLabels from './ModalDeliveryLabels.vue'
import ModalDeliveryLabelsPrint from './ModalDeliveryLabelsPrint.vue'
import ModalAssignSummary from './ModalAssignSummary.vue'
import * as moment from 'moment'

export default {
  // props: ['form', 'openModal', 'cleanForm', 'statusId', 'advancedFilters', 'getDeliveries'],
  components: {ModalExtendedPrintedFilter, ModalDeliveryLabels, ModalDeliveryLabelsPrint, ModalAssignSummary},
  data () {
    return {
      ROLES : {
        marketplace: 'marketplace',
        ecommerce: 'ecommerce',
        seller: 'seller',
        admin: 'admin'
      },
      buttons: [],
      filters: {},
      formFilter: {},
      selectedRadioOption: {},
      dialogOpenExtendedFilter: false,
      fields: [],
      fieldsType: [],
      buttonSend: { title: 'Buscar', icon: 'SearchIcon', color: 'warning' },
      schema: [],
      rows: [],
      // currentFilter: {},
      advancedFilters: {},
      initialDateRange: {
        start: moment().add(-20, 'days').toDate(),
        end: moment().add(3, 'days').toDate()
      },
      initialStatusOption: { text: 'Todos', id: '26,75' },
      pagination: {
        page: 1,
        total: 40,
        limit: 20
      },
      keyFormRender: 0,
      clearModalFilters: 0,
      // controlHeight: { class: 'row mb-2 spacing-label-field' },
      user: null,
      loading: {
        first: true,
        printed: false,
        excel: false
      },
      // myInput: null,
      selectedRows: [],
      serviceType: null,
      deliveryLabelUrl: null,
      disabledPanel: true,
      orderedLabels: [],
      // showingActions: false,
      deliveryService: new DeliveryService(this),
      listCount: {
        withSummaries: 0,
        noSummaries: 0
      },
      selectedCheck: 1,
      optionState: []
    }
  },
  computed: {
    ...mapGetters({
      mySession: 'getSession'
    })
  },
  mounted () {
    this.getAllInitialData()
  },
  watch: {
    mySession () {
      if (this.mySession.id) this.setInitialData()
    },
    selectedRows () {
      if (this.selectedRows.length === 0) this.disabledPanel = true
      else this.disabledPanel = false
    },
    'pagination.page' () {
      this.filterForm(this.filters)
    },
    'pagination.limit' () {
      this.filterForm(this.filters)
    }
  },
  methods: {
    getAllInitialData () {
      if (this.mySession.id) this.setInitialData()
    },
    setInitialData () {
      this.schema = [
        {label: 'Empresa', key: 'company.name', sortable: true},
        {label: 'Estado', key: 'status', sortable: true, useSlot: true},
        {label: 'N° de envío', key: 'imported_id', sortable: true, useSlot: true},
        {label: 'N° de Manifiesto', key: 'summary_id', sortable: true},
        {label: 'Orden de flete', key: 'tracking_number', sortable: true},
        {label: 'Cliente', key: 'client_name', sortable: true, useSlot: true},
        {label: 'Place_level3', key: 'place_name', sortable: true},
        {label: 'Courier', key: 'carrier.name', sortable: true, wrap: true},
        {label: 'Fecha creación', key: 'created_at', sortable: true, wrap: true}
      ]
      this.optionState = [
        {id: 1, html: `Con Manifiesto <span class="badge badge-primary badge-pill">${this.listCount.withSummaries}</span>`, value: true}, 
        {id: 2, html: `Sin Manifiesto <span class="badge badge-warning badge-pill">${this.listCount.noSummaries}</span>`, value: false}
      ]
      this.fieldsType = [  
        {
          fieldType: 'FieldRadio', 
          name: 'activated', 
          containerClass: 'col-sm-12 col-md-6 container-info', 
          align: 'h', 
          options: this.optionState, 
          change: this.changeType
        }
      ]
      this.selectedRadioOption.activated = this.optionState[0]
      this.fields = [
        {
          fieldType: 'FieldDatepicker', 
          name: 'date', 
          label: 'Fecha de creación', 
          range: true, 
          containerClass: 'col-sm-12 col-md-6 col-lg-3'
        },
        { 
          fieldType: 'FieldTags', 
          label: 'Número de envío', 
          name: 'delivery_number', 
          clearable: true, 
          color:'primary', 
          placeholder: 'Ingrese número de envío', 
          tagsOption: {
            limitTagsText: this.$t('Limite de ID alcanzadas'),
            duplicateTagText: this.$t('Número de envío duplicado'),
            limit: 15
          }
        }
      ]
      this.formFilter = {
        date: this.initialDateRange,
        status: this.initialStatusOption,
        sameday: false
      }
      this.customTableByRole()
      // this.getDeliveries()
      this.filterList(this.formFilter)
    },
    getDeliveries () {
      // const payloadFilters = this.setPayload('filterList')
      this.selectedRows = []
      this.loading.printed = true
      this.deliveryService.callService('getDeliveriesEstambul', this.filters)
        .then(resp => {
          this.setDataRows(resp.data, this.filters.with_summary)
        })
        .catch(err => { 
          console.error(err)
        })
        .finally(end => {
          this.loading.first = false
          this.loading.printed = false
        })
    },
    setDataRows (data, withSummary) {
      this.rows = data.map(el => ({...el, id: el.id}))
      if (withSummary) {
        this.listCount.withSummaries = this.rows.length
        this.optionState[0].html = `Con Manifiesto <span class="badge badge-primary badge-pill">${this.listCount.withSummaries}</span>`
      }
      else {
        this.listCount.noSummaries = this.rows.length
        this.optionState[1].html = `Sin Manifiesto <span class="badge badge-warning badge-pill">${this.listCount.noSummaries}</span>`
      }
    },
    customTableByRole () {
      if (this.mySession.role === 'ecommerce') {
        this.schema.shift()
      }
    },
    changeType (name, selectedData) {
      this.selectedRows = []
      this.selectedCheck = selectedData.id
      const summaryIdRow = {label: 'N° de Manifiesto', key: 'summary_id', sortable: true}
      const index = this.schema.findIndex(el => el.key === 'imported_id') + 1
      if (selectedData.value) this.schema.splice(index, 0, summaryIdRow)
      else this.schema = this.schema.filter(row => row.key !== 'summary_id')
      // this.getDeliveries()
      this.filterForm(this.filters)
    },
    openAdvancedFiltersModal () {
      this.$bvModal.show('modalExtendedFilter')
    },
    filterForm (form) {
      this.filterList({...this.advancedFilters, ...form})
    },
    filterList (data) {
      this.filters = {}
      if (data.date) {
        this.filters['created_at_from'] = this.$options.filters.moment(data.date.start, 'YYYY-MM-DD')
        this.filters['created_at_to'] = this.$options.filters.moment(data.date.end, 'YYYY-MM-DD')
      }
      if (data.delivery_number?.length > 0) this.filters['delivery_number'] = data.delivery_number.toString()
      if (data.company_id) this.filters['shipper_id'] = data.company_id.id
      if (data.summary_id) this.filters['summary_id'] = Number(data.summary_id)
      if (data.country_id) this.filters['country_id'] = data.country_id.id
      if (data.carrier) this.filters['carrier_code'] = data.carrier.code
      if (data.carrier_service) this.filters['service_type'] = data.carrier_service.code
      if (data.distribution_type?.length > 0) this.filters['contract_type'] = data.distribution_type.map(distribution => distribution.contract_type).toString()
      if (data.place) this.filters['place_id'] = data.place.platform_id
      if (data.sameday) this.filters['filter_by_sameday'] = 1
      if (data.status) this.filters['status_in'] = data.status.id
      else this.filters['status_in'] = '26,75'

      this.filters.with_summary = this.selectedRadioOption.activated.value
      // En caso de NO ser admin seteara el mkp o seller dependiendo del role del user
      if (this.mySession.role !== this.ROLES.admin) {
        this.filters = this.setFiltersByRole(this.filters)
      }

      if (this.pagination.page !== 1) this.pagination.page = 1
      else this.getDeliveries()
    },
    /** 
     * Función que setea filtros según el rol del usuario
    */
    setFiltersByRole (filters) {
      if (this.mySession.role === this.ROLES.ecommerce || this.mySession.role === this.ROLES.seller) filters = { ...filters, shipper_id: this.mySession.shipper.id }
      if (this.mySession.role === this.ROLES.marketplace) filters = { ...filters, organization_id: this.mySession.organization.id }
      return filters
    },
    cleanFilter () {
      this.formFilter = {
        date: this.initialDateRange,
        status: this.initialStatusOption
      }
      this.clearModalFilters++
      this.filterList(this.formFilter)
    },
    openDeliveryDetails (deliveryId, companyId) {
      this.m_openWinBlank(`/deliveries/${deliveryId}`)
    },
    downloadExcel () {
      const payloadExport = this.setPayload('export') 
      this.loading.excel = true
      this.deliveryService.callService('exportDeliveries', payloadExport, null)
        .then(resp => {
          if (resp.data.url) window.open(resp.data.url, '_blank')
          else this.$alert(this.$t(resp.data.message))
        })
        .catch(err => {
          this.$alert(this.$t('Ocurrió un problema al exportar el archivo'))
        })
        .finally(end => {
          this.loading.excel = false
        })
    },
    collectFullFilter (data) {
      this.advancedFilters = data
      this.$bvModal.hide('modalExtendedFilter')
      delete data.activated
      delete this.formFilter.status
      data = {...this.advancedFilters, ...this.formFilter}
      this.filterList(data)
    },
    updateFilterDate (date) {
      this.formFilter.date = date
      this.keyFormRender++
      // this.$refs.printedForm.updateUIForm()
    },
    collectLabelsPrintData (data) {
      this.orderedLabels = data
      this.$bvModal.hide('modalDeliveryLabels')
      this.showLabelsPrints()
    },
    showLabelsPrints () {
      this.$bvModal.show('modalDeliveryLabelsPrint')
    },
    showLabelsPrintOptions (process) {
      this.serviceType = process
      if (process === 'only-assign') this.$bvModal.show('modalAssignSummary')
      else this.$bvModal.show('modalDeliveryLabels')
    },
    setPayload (typePayload) {
      const payload =  {
        ...this.filters,
        with_summary: this.selectedRadioOption.activated.value,
        limit: this.pagination.limit, 
        page: this.pagination.page
      }
      if (this.mySession.role !== this.ROLES.admin) payload.user = {}
      const roles = {
        admin: 1,
        ecommerce: 2,
        seller: 2,
        pudo: 3,
        marketplace: 4
      }

      if (typePayload === 'export') {
        if (['marketplace'].includes(this.mySession.role)) {
          payload.user.organization_id = this.mySession.organization.id
          payload.user.role_id = roles[this.mySession.role]
        }
        if (['ecommerce', 'seller'].includes(this.mySession.role)) {
          payload.user.shipper_id = this.mySession.shipper.id
          payload.user.role_id = roles[this.mySession.role]
        }
      }

      return payload
    }
  }
}
</script>

<style lang='scss'>
.search-advanced {
    text-decoration: underline !important;
}
.custom-switch-success {
  margin-top: 0.5rem;
  > label {
    font-size: 14px !important;
  }
}
</style>
