<template>
  <div>
    <logistics-form
      type="DCreate"
      showLoadingAlert="true"
      :internalLevels="internalLevels"
      @onSave="generatePayload"
      ref="createDeliveryForm"
    />
    <modal-maps :dataDelivery="dataDelivery" @putDelivery="createDelivery"/>
  </div>
</template>

<script>
import ModalMaps from './ModalMaps.vue'
import LogisticsForm from './LogisticsForm.vue'
export default {
  name: 'create-deliveries',
  props: ['changeTab'],
  components: { LogisticsForm, ModalMaps }, 
  data () {
    return {
      internalLevels: {
        cl: {
          required: [3],
          display: [3]
        },
        co: {
          required: [2],
          display: [1, 2]
        },
        pe: {
          required: [3],
          display: [2, 3]
        },
        ar: {
          required: [3],
          display: [1, 3]
        },
        mx: {
          required: [4],
          display: [1, 2, 4],
          disabled: [2, 4]
        },
        ec: {
          required: [3],
          display: [1, 3]
        },
        uy: {
          required: [2],
          display: [1, 2]
        }
      },
      levelPlace: {
        co: 'level2',
        pe: 'level3',
        cl: 'level3',
        mx: 'level4',
        ar: 'level3', // No se hacen envios en pais por la plataforma
        ec: 'level3' // No se hacen envios en pais por la plataforma
      },
      levelPlaceLevel1: {
        co: 'level1',
        pe: 'level2',
        ar: 'level1', // No se hacen envios en pais por la plataforma
        ec: 'level1' // No se hacen envios en pais por la plataforma
      },
      formDeliveryForSetting: {},
      dataDelivery: {}
    }
  },
  watch: {},
  mounted () {},
  methods: {
    generatePayload (form) {
      const country_co = form.company.country_code.toLowerCase()
      const zipcode = country_co === 'mx' ? form.zipcode.id : form.zipcode
      // chequeamos si el envio se realizará a un EP o a un domicilio
      const payload = {
        imported_id: form.imported_id || '',
        product: form.description,
        price: this.replaceSymbol(form).price,
        n_packages: form.n_packages,
        full_address: form.full_address || '',
        zip_code: zipcode || '',
        information: form.information || '',
        name: form.name || '',
        email: form.email || '',
        phone: form.cellphone || '',
        weight: form.weight || '',
        volume: country_co === 'co' && !form.company.mkp_customization?.replace_null_volume ? null : form.volume || '',
        carrier_code: form?.carrier ? { 'carrier_configuration_code': form?.carrier?.code || null } : null,
        service_code: form?.service?.code || null,
        carrier_type: form?.type?.code || null,
        carrier_attribute: form?.attribute ? form.attribute.map(item => item.code) : null,
        dimensions: {
          width: form.width || null,
          height: form.height || null,
          length: form['length'] || null
        }
      }

      if (form.amount_to_collect) {
        form.amount_to_collect = this.replaceSymbol(form).amount_to_collect || 0
      }
      if (form.distributionType.id === 'pudo' || form.distributionType.id === 'agency') {
        if (form.pudo) {
          payload.pudo = form.pudo.code
          payload.full_address = ''
          payload.zip_code = ''
        } else if (form.agency) {
          payload.pudo = form.agency.code
          payload.full_address = ''
          payload.zip_code = ''
        }
        delete payload.place_id
        delete payload.address
      } else {
        //"place" -> CO:level2, PE:level3, AR: ??, EC:level3, CL: level3, MX: level4
        payload.place = form[this.levelPlace[country_co]].name

        // Se muestra para CO, PE, AR, EC, departamento, Provincia, Provincia, Provincia
        //"place_level_1" -> CO: 'level1', PE: 'level2', AR: 'level1', EC: 'level1' 
        if (['co', 'pe', 'ar', 'ec'].includes(country_co)) {
          payload.place_level_1 = form[this.levelPlaceLevel1[country_co]].name
        }
      }

      payload.company_id = form.company.id
      payload.warehouse = form.destination.code || ''

      // Seteamos el valor del form en una variable global para utilizarlo en otra función
      this.formDeliveryForSetting = form
      // Si algun carrier esta dentro del array llamamos a la función que abre el modal y hace la confirmación de dirección
      const carrierCodes = ['UBN', 'PYPE', 'PUP', 'COMV', 'UBN', 'PEYG']
      
      if (payload.carrier_code && (carrierCodes.includes(payload.carrier_code?.carrier_code)) && payload.company_id !== 6965 && form.distributionType.id === 'home') {
        this.$refs.createDeliveryForm.updateButtonForm(false)
        this.confirmAddressFromMap(payload, form)
      } else {
        // Si no cumple con la condición llamamos a la función que llama directamente al servicio de agregar envio
        this.createDelivery(payload)
      }
    },
    replaceSymbol(delivery) {
      const priceAndAmount = {
        price: delivery.price,
        amount_to_collect: delivery.amount_to_collect
      }

      Object.keys(priceAndAmount).map((key) => {
        priceAndAmount[key] = delivery.company.country_id === 4 || delivery.company.country_id === 2 ? parseFloat(`${priceAndAmount[key]}`.replace(/[$,S/]/g, '')) : parseFloat(`${priceAndAmount[key]}`.replace(/[$.]/g, '').replace(/,/g, '.'))
      })

      return priceAndAmount
    },
    createDelivery (payload) {
      this.$store.dispatch('fetchService', {
        name: 'postNewDelivery',
        queryParams: payload,
        onSuccess: this.sucessDeliveryCreated,
        onError: (err) => this.errorDeliveryCreated(err),
        hideAlert: true
      })
    },
    sucessDeliveryCreated() {
      this.$refs.createDeliveryForm.updateButtonForm(false)
      this.$success(
        this.$t('msg-exito-crear-envio'),
        () => this.$emit('changeTab', 'labels'),
        this.$t('felicitaciones'),
        'Continuar',
        null, null, null, false
      )
    },
    errorDeliveryCreated (err) {
      this.$refs.createDeliveryForm.updateButtonForm(false)
      let error = ''
      if (err?.status === 422) {
        error = Object.values(JSON.parse(err.data.data))[0] || 'Error desconocido'
      } else {
        error = err
      }
      this.$alert(this.$t('msg-problema-crear-envio', { code: error }))
    },
    confirmAddressFromMap(payloadD, form) {
      this.dataDelivery = {
        payload: payloadD,
        country_id: form.company.country_id
      }
      // Abrimos el modal
      this.$bvModal.show('ModalMaps')
    }
  }
}
</script>